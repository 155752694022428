// Packages
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    width: "100%",
  },
  chip: {
    margin: theme.spacing(0.25),
  },
  section: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  currentIcon: {
    color: "#4caf50",
  },
  notCurrentIcon: {
    color: "#dc004e",
  },
  menuItemText: {
    paddingLeft: 5,
    verticalAlign: "bottom",
  },
  menuItemA: {
    verticalAlign: "bottom",
    color: "#1f4287",
    transform: "translateY(0%)",
    marginLeft: "auto",
    textAlign: "right"
  },
  menuItemB: {
    verticalAlign: "bottom",
    color: "#1f4287",
    transform: "translateY(0%)",
    marginLeft: "auto",
    textAlign: "right"
  },
  rightContent: {
    textAlign: "right",
    marginRight: "auto",
    right: "0px",
  },
  leftContent: {
    textAlign: "left",
    left: "0px",
    right: "100%",
    marginRight: "auto",
    fontSize: "0.8571rem"
  },
  downloadCard: {
    display: "flex",
    justifyContent: "space-between",
  },
  categoryTypesInfo: {
    display: "flex",
    justifyContent: "space-between",
  },
  list: {
    fontSize: "0.8571rem",
    display: "flex",
    flexDirection: "column"
  },
  listItemPackageTypesInfo: {
    textDecoration: "none !important",
    fontSize: "0.8571rem",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    color: "#204286",
    whiteSpace: "collapse balance",
    "&:hover": {
        textDecoration: "none !important",
        color: "#0097AC",
        cursor: "pointer"
    },  

  },
  packageTypesInfo: {
    fontSize: "0.8571rem",
    textAlign: "left",
  }

}));
