/**
 * @description The Footer
 * @module components/Footer
 */

// Packages
import React from "react";

// MUI Components
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";

// Styles
import { useStyles } from "./styles";

export default function Footer(props) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Paper className={classes.root}>
        <Grid container className={classes.container}>
          <Grid item className={classes.copyrightContainer}>
            <Link
              underline="none"
              variant="body1"
              className={classes.copyright}
            >
              &#169; {new Date().getFullYear()} {`v`}
              {process.env.REACT_APP_VERSION}
            </Link>
          </Grid>
          <Grid item className={classes.right}>
            <Grid item>
              <Link
                variant="body1"
                href="https://www.aaisonline.com/en/terms-of-use"
                className={classes.link}
              >
                Legal
              </Link>
            </Grid>
            <Grid item className={classes.separator}>
            </Grid>
            <Grid item>
              <Link
                variant="body1"
                href="mailto:membership@aaisonline.com"
                className={classes.link}
              >
                Contact
              </Link>
            </Grid>
            <Grid item className={classes.separator}>
            </Grid>
            <Grid item>
              <Link
                variant="body1"
                href="https://www.aaisonline.com"
                className={classes.link}
              >
                AAISonline
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
}
