// Packages
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: theme.typography.pxToRem(20),
    textDecoration: "none",
  },
  email: {
    marginTop: 10,
    marginBottom: 10,
  },
  avatar: {
    width: "100%",
    height: "100%",
    maxHeight: 200,
    maxWidth: 200,
    minWidth: 50,
    minHeight: 50,
    cursor: "pointer",
  },
  uploadButton: {
    marginTop: 10,
  },
  paper: {
    paddingTop: 20,
    paddingRight: 20,
    paddingLeft: 20,
    paddingBottom: 50,
  },
  section: {
    width: "100%",
  },
  divider: {
    marginTop: 20,
    marginBottom: 20,
  },
  link: {
    textDecoration: "none !important",
    cursor: "pointer",
    transition: "color 0.2s",
    "&:hover": {
        color: "#0097AC"
    }
  }
}));
