/**
 * @description AssetApi Module. This will have all the API's needed
 * to interact with the backend API's for the Assets
 * @module apis/AssetApi
 * @since 1.0.0
 * @author Findlay Clarke <findlayc@aaisonline.com>
 * @public
 */

import { API } from "aws-amplify";

export async function createManifestV1(
  lineId,
  states,
  materials,
  persist = true
) {
  if (!lineId) throw Error("Line is not correct");
  if (!states || !Array.isArray(states)) throw Error("States is not correct");
  if (!materials || !Array.isArray(materials))
    throw Error("Materials is not correct");

  const path = "/assets/v1/manifests";
  const body = {
    product: lineId,
    states: states,
    packageTypes: materials,
    persist: persist,
  };

  const init = { body };

  try {
    return await API.post("ASSET", path, init);
  } catch (error) {
    console.log(error);
  }
}

export async function createManifestV2(
  lineId,
  packageTypes,
  states,
  persist = true,
  imgClass_s = undefined,
  publicationTypeCategory_query = undefined,
  searchTerm = undefined,
  excludeFileContent = undefined,
  effectiveDate = undefined,
  effectiveOldestDate = undefined,
  plans = undefined
) {
  /*if (!lineId) throw Error("Line is not correct");
  if (!states || !Array.isArray(states)) throw Error("States is not correct");
  if (!packageTypes || !Array.isArray(packageTypes))
    throw Error("Materials is not correct");*/

  const path = "/assets/v2/manifests";
  const body = {
    products: lineId,
    states: states,
    publicationType: packageTypes,
    persist,
    imgClass_s,
    publicationTypeCategory_query,
    searchTerm,
    excludeFileContent,
    effectiveDate,
    effectiveOldestDate,
    plans
  };

  const init = { body };

  try {
    return await API.post("ASSET", path, init);
  } catch (error) {
    return error;
  }
}

export async function getManifestProdV1(lineId, states, materials) {
  if (!lineId) throw Error("Line is not correct");
  if (!states || !Array.isArray(states)) throw Error("States is not correct");
  if (!materials || !Array.isArray(materials))
    throw Error("Materials is not correct");

  const path = "/assets/v1/manifests-prod";
  const body = {
    product: lineId,
    states: states,
    packageTypes: materials,
    persist: false,
  };

  const init = { body };

  try {
    return await API.post("ASSET", path, init);
  } catch (error) {
    console.log(error);
  }
}

export async function getSignedUrlV1(key) {
  if (!key) throw Error("key is not correct");

  const path = "/assets/v1/getSignedUrl";
  const body = {
    key,
  };

  const init = { body };

  try {
    return await API.post("ASSET", path, init);
  } catch (error) {
    console.log(error);
  }
}

export async function createZipFileV1(manifestId) {
  if (!manifestId) throw Error("manifestId is required");

  const path = `/assets/v1/manifests/${manifestId}/zip`;

  try {
    return await API.post("ASSET", path).catch((error) => {
      return null;
    });
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function checkZipFileV1(manifestId) {
  if (!manifestId) throw Error("manifestId is required");

  const path = `/assets/v1/manifests/${manifestId}/zipcheck`;

  try {
    return await API.post("ASSET", path).catch((error) => {
      return null;
    });
  } catch (error) {
    console.log(error);
    return null;
  }
}


export async function getUserManifestMetaData(userId, lineKey = null) {
  if (!userId) throw Error("userId is required");

  let path = `/assets/v1/manifests?userId=${userId}`;
  if (lineKey && lineKey !== 'All') path += `&lineKey=${lineKey}`;

  try {
    return await API.get("ASSET", path);
  } catch (error) {
    console.log(error);
  }
}

export async function getLineUpdates(lineKey) {
  if (!lineKey) throw Error("Line is undefined");

  const path = `/lines/${lineKey}/updates`;

  try {
    return await API.get("ASSET", path);
  } catch (error) {
    console.log(error);
  }
}

export async function search(searchTerm, filters) {
  const path = `/assets/v1/search`;
  const init = {
    body: {
      term: searchTerm,
      filters: filters,
    },
  };

  try {
    return await API.post("ASSET", path, init);
  } catch (error) {
    console.log(error);
  }
}

export async function get(id) {
  const path = `/assets/v1/index?id=${id}`;
  try {
    return await API.get("ASSET", path);
  } catch (error) {
    console.log(error);
  }
}
export async function download(item) {
  const IsNumeric = (num) => /^-{0,1}\d*\.{0,1}\d+$/.test(num);
  const filesArr = [];
  const files = item._source.files;
  files.map( (fileObj) => {
    const thisFilenameKey = fileObj.filename.toLowerCase().replace(fileObj.extension, '');
    const lastChar = thisFilenameKey.slice(-1);
    let thisFileName = item._source.displayName.trim();
    // eslint-disable-next-line
    thisFileName = thisFileName.replace(/[^a-zA-Z0-9\s_\(\)]/gi, '');
    if(!IsNumeric(lastChar)){
      thisFileName = thisFileName + lastChar;
    }
    filesArr.push({'key': fileObj.filename, 'path': '/', 'name': `${thisFileName.trim()}${fileObj.extension}`});
  });
  const path = `/assets/v2/download`;
  const init = {
    body: {
      files: filesArr,
    },
  };
  try {
    return await API.post("ASSET", path, init);
  } catch (error) {
    console.log(error);
  }
}

/**
 * @description Scrolls the search results to the next batch
 * @param {string} scrollId The scroll id from the search call to paginate
 */
export async function scroll(scrollId) {
  const path = `/assets/v1/search`;
  const init = {
    body: {
      scrollId: scrollId,
    },
  };

  try {
    return await API.put("ASSET", path, init);
  } catch (error) {
    console.log(error);
  }
}

/**
 * @description Deletes an old scollId
 * @param {string} scrollId The scroll id from the search call to delete
 */
export async function scrollDelete(scrollId) {
  if(!scrollId || scrollId.length === 0) return null;
  const path = `/assets/v1/scroll`;
  const init = {
    body: {
      scrollId: scrollId,
    },
  };

  try {
    return await API.del("ASSET", path, init);
  } catch (error) {
    console.log(error);
  }
}

export async function searchSendAnalytics(searchId, q, filters) {
  const path = `/assets/v1/search/${searchId}`;
  const init = {
    body: {
      q: q,
      filters: filters,
    },
  };

  try {
    return await API.post("ASSET", path, init);
  } catch (error) {
    console.log(error);
  }
}
