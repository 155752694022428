import React, { useContext, useState, useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import * as HSCApi from "../../apis/HealthStatusCheckApi";
import SERVICE_NAMES from "../../enums/SERVICE_NAMES";

// Context
import MeContext from "contexts/MeContext";
// Services
import * as userUtil from "util/userUtil";

export default function HealthStatusCheck() {
  const me = useContext(MeContext);
  const isAdmin = userUtil.isAdmin(me);
  const servicerows = SERVICE_NAMES.map((serviceName) => {
    return { serviceName: serviceName, status: 0 };
  });

  const [allStatus, setallStatus] = useState(servicerows);
  const [healthSummary, setHealthSummary] = useState(0);//0: loading, 1: good, -1 = bad //"All services are available."

  useEffect(() => {
    if(isAdmin){
      testhealthStatus();
    }
  }, [me]);

  async function testhealthStatus() {
    const receivedStatus = Array(SERVICE_NAMES.length).fill(0);
    receivedStatus[0] = await HSCApi.checkLoginHealth();
    receivedStatus[1] = await HSCApi.checkDownloadHealth();
    let allGood = true;
    const newServicerows = servicerows.map((row, index) => {
      if(receivedStatus[index] !== true){
        allGood = false;
      }
      return {
        serviceName: row.serviceName,
        status: receivedStatus[index],
      };
    });
    setallStatus(newServicerows);
    if(allGood === false){
      setHealthSummary(-1);
    }
    else{
      setHealthSummary(1);
    }
    return;
  }
  if(!isAdmin){
    return null;
  }

  return (
    <Grid container justifyContent="center" spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5" color="primary" align="center">
          Health Check Dashboard
        </Typography>
      </Grid>
      <Grid item component={Paper} md={6} xs={12} sm={10} lg={6} xl={4}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow key={'health-check-top-row'}>
              <TableCell fontWeight="fontWeightBold" color="textPrimary">
                <Typography variant="body1">Functionality</Typography>
              </TableCell>
              <TableCell align="right" color="textPrimary">
                <Typography variant="body1">Status</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>

          <TableRow key={'overall'}>
            <TableCell xs={7}>
              <Typography variant="body1" color="textPrimary" id="health-check-overall" name="health-check-overall">
                <b>Overall</b>
              </Typography>
            </TableCell>
            <TableCell align="right">
              {healthSummary === 1 && (
                <Typography
                  style={{ color: "#3BB143" }}
                  id="health-check-pass"
                  name="health-check-pass"
                  data-test="CheckIconPrimary"
                >All services are available.</Typography>
              )}{ healthSummary === -1 && (
                <Typography
                  color="secondary"
                  id="health-check-fail"
                  name="health-check-fail"
                  data-test="CheckIconSecondary"
                >A service is unavailable. Please investigate.</Typography>
              )}{ healthSummary === 0 && (
                <Typography
                  color="textPrimary"
                  id="health-check-load"
                  name="health-check-load"
                  data-test="CheckIconSecondary"
                >Loading...</Typography>
              )}
            </TableCell>
          </TableRow>

            {servicerows.map((row, index) => (
              <TableRow key={row.name}>
                <TableCell xs={7} key={`health-check-row-${row.name}`}>
                  <Typography variant="body1" color="textPrimary" id={`health-check-servicename-${row.serviceName}`} name={`health-check-servicename-${row.serviceName}`} >
                    {row.serviceName}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  {allStatus[index]["status"] === true && allStatus[index]["status"] !== 0 && (
                    <Typography
                      style={{ color: "#3BB143" }}
                      id="health-check-available"
                      name="health-check-available"
                      data-test="CheckIconPrimary"
                    >Available</Typography>
                  ) } { allStatus[index]["status"] !== true && allStatus[index]["status"] !== 0 &&  (
                    <Typography
                      color="secondary"
                      id="health-check-disruption"
                      name="health-check-disruption"
                      data-test="CheckIconSecondary"
                    >Disruption</Typography>
                  )}{allStatus[index]["status"] === 0 &&  (
                    <Typography
                      color="textPrimary"
                      id="health-check-loading"
                      name="health-check-loading"
                      data-test="CheckIconSecondary"
                    >Loading...</Typography>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
}
