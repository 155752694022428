/**
 * @description A generic Risk awareness card for ArcGIS iframe content
 * @module components/GenericRiskCard
 */

// Packages
import React from "react";

// MUI Components
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Button from "@material-ui/core/Button";
import Fade from "@material-ui/core/Fade";

// Styles
import { useStyles } from "./styles";

export default function AddressCard(props) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Fade in={true} timeout={1000}>
        <Card className={classes.card}>
          <CardHeader className={classes.cardHeader} title={props.title} />
          <CardContent className={classes.map}>
            <iframe
              width="100%"
              height="263"
              frameBorder="0"
              scrolling="no"
              marginHeight="0"
              marginWidth="0"
              title={props.title}
              src={props.src}
              allowFullScreen
            ></iframe>
          </CardContent>
          <CardActions className={classes.cardActions}>
            {props.callToActionText ? (
              <Button
                size="small"
                color="primary"
                href={props.callToActionHref}
              >
                {props.callToActionText}
              </Button>
            ) : (
              <Button size="small" color="secondary" href={props.src}>
                Open
              </Button>
            )}
          </CardActions>
        </Card>
      </Fade>
    </React.Fragment>
  );
}
