// Packages
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    borderRadius: 0,
    backgroundColor: theme.palette.grey[100],
  },
  topSection: {
    marginBottom: theme.spacing(2),
  },
}));
