/**
 * @description The enumeration of the Address types
 * @since 1.0.0
 * @enum
 * @readonly
 * @public
 */

const SERVICE_NAMES = ["Login", "Download Material"];
export default SERVICE_NAMES;
