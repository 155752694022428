// Packages
import React, { useState, useEffect } from "react";

// MUI Components
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Accordion from "@material-ui/core/Accordion";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Switch from "@material-ui/core/Switch";
import { Button, LinearProgress } from "@material-ui/core";

// MUI Icons
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

// Components
import SummaryBar from "components/SummaryBar";

// Styles
import { useStyles } from "./styles";

// Data
import STATES from "enums/STATES";
import LINE_CATEGORIES from "enums/LINES_CATEGORIES";

// Services
import * as UserApi from "apis/UserApi";

const STATE_COUNT = Object.keys(STATES).length;
const NOT_TYPE = "BULLETINS";

export default function Bulletins(props) {
  const classes = useStyles();
  const [notificationPreferences, setNotificationPreferences] = useState({});
  const [loadingLine, setLoading] = useState();

  useEffect(() => {
    getPrefs();
  }, []);

  async function getPrefs(line = "*") {
    let newPrefs = {};
    if (line === "*") {
      newPrefs = {}; //wipe out the whole thing
    } else {
      newPrefs = Object.assign(newPrefs, notificationPreferences);
      delete newPrefs[line]; //remove the line and start a fresh
    }
    const prefs = await UserApi.getNotificationPreferences(NOT_TYPE, line);
    for (let i = 0; i < prefs.length; i++) {
      const record = prefs[i].node;
      const tokens = record.split("-");
      const tokenLine = tokens[1];
      const tokenState = tokens[2];
      if (!newPrefs[tokenLine]) {
        //first time
        newPrefs[tokenLine] = {};
      }
      newPrefs[tokenLine][tokenState] = true;
    }

    setNotificationPreferences(newPrefs);
    setLoading();
  }

  function isChecked(line, state) {
    if (notificationPreferences[line]) {
      if (notificationPreferences[line][state] !== undefined) {
        return notificationPreferences[line][state];
      } else if (notificationPreferences[line]["*"]) return true;
    }

    return false;
  }

  async function handleFlipAll(line) {
    setLoading(line.key);
    const flipCount = getSubscribedCount(line);

    if (flipCount < STATE_COUNT) {
      await UserApi.addNotificationPreference(NOT_TYPE, line.key, "*");
    } else {
      await UserApi.removeNotificationPreference(NOT_TYPE, line.key, "*");
    }

    await getPrefs(line.key);
  }

  async function handleChange(line, state, checked) {
    const newPrefs = Object.assign({}, notificationPreferences);
    if (!newPrefs[line.key]) {
      newPrefs[line.key] = {}; //init if first one
    }

    if (checked) {
      //add a selection
      newPrefs[line.key][state.key] = checked;
      setNotificationPreferences(newPrefs);
      UserApi.addNotificationPreference(NOT_TYPE, line.key, state.key);
    } else {
      //removing a selection

      if (newPrefs[line.key]["*"]) {
        /**
         * This operation is very expensive. it requires to expand the *
         * into the all the states but one. So we make blocking calls to first
         * remove the * and then add all the individual states except that one
         */
        setLoading(line.key);
        //if there is a * we need to explode it
        await UserApi.removeNotificationPreference(NOT_TYPE, line.key, "*");

        //now add all the other states except the one that was unselected
        const states = Object.keys(STATES);
        for (let i = 0; i < states.length; i++) {
          const stateKey = STATES[states[i]].key;
          if (stateKey !== state.key) {
            // we wait here because we need to "sync" the changes. Could probably
            // TODO: make this faster with a call to Promise.all()
            // eslint-disable-next-line no-await-in-loop
            await UserApi.addNotificationPreference(
              NOT_TYPE,
              line.key,
              stateKey
            );
          } else {
            //doing nothing. just making sure nothing happens as this state
            //was the one that was selected
          }
        }
        getPrefs(line.key);
      } else {
        //its just a regular delete
        UserApi.removeNotificationPreference(NOT_TYPE, line.key, state.key);
        delete newPrefs[line.key][state.key];
        setNotificationPreferences(newPrefs);
      }
    }
  }

  function getSubscribedCount(line) {
    const lineObject = notificationPreferences[line.key];
    if (!lineObject) return 0;

    const stateKeys = Object.keys(lineObject);

    let count = 0;

    for (let i = 0; i < stateKeys.length; i++) {
      const stateKey = stateKeys[i];
      if (stateKey === "*" && notificationPreferences[line.key][stateKey]) {
        return STATE_COUNT;
      }
      if (notificationPreferences[line.key][stateKey]) {
        count++;
      }
    }

    return count;
  }

  function getStates(lineCategory, line) {
    const statesToggle = Object.keys(STATES).map((stateKey) => {
      const state = STATES[stateKey];
      return (
        <Grid
          item
          xs={6}
          sm={6}
          md={3}
          lg={2}
          xl={1}
          key={`st${lineCategory.key}-${line.key}-${state.key}`}
        >
          <FormControlLabel
            control={
              <Switch
                checked={isChecked(line.key, state.key)}
                disableRipple={true}
                onChange={(event) => {
                  handleChange(line, state, event.target.checked);
                }}
                color="primary"
              />
            }
            label={state.title}
          />
        </Grid>
      );
    });

    return statesToggle;
  }

  function getLine(lineCategory, line) {
    return (
      <Accordion
        defaultExpanded={false}
        TransitionProps={{ unmountOnExit: true }}
        // onChange={(event, expanded) =>
        //   setExpanded(lineCategory, line, expanded)
        // }
        key={`ep-${lineCategory.key}-${line.key}`}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            data-test={line.key}
          >
            <Grid item xs={12}>
              {loadingLine === line.key && <LinearProgress></LinearProgress>}
            </Grid>
            <Grid item>
              <Typography variant="h6" color="primary">
                {line.title}
                {` (${getSubscribedCount(line)}/${STATE_COUNT})`}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={(event) => {
                  handleFlipAll(line);
                  event.stopPropagation();
                }}
              >
                {getSubscribedCount(line) < STATE_COUNT
                  ? "Select All"
                  : "UnSelect All"}
              </Button>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            {getStates(lineCategory, line)}
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  }

  function getLineCategory(lineCategory) {
    const lines = lineCategory.lines.map((line) => {
      return getLine(lineCategory, line);
    });

    return (
      <SummaryBar
        title={lineCategory.title.toUpperCase()}
        bgColor={lineCategory.color}
        expanded={false}
        hideExpand={true}
        key={`sb-${lineCategory.key}`}
      >
        <div className={classes.lineCategory}>{lines}</div>
      </SummaryBar>
    );
  }

  function getCategories() {
    const sections = Object.keys(LINE_CATEGORIES)
      .sort()
      .map((lineCategoryName) => {
        const lineCategory = LINE_CATEGORIES[lineCategoryName];
        return getLineCategory(lineCategory);
      });
    return sections;
  }

  return <div className="root">{getCategories()}</div>;
}
