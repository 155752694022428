// The commented code responds to story US99853

// Packages
import React, { useContext, useState, useEffect, useRef } from "react";
import { Auth } from "aws-amplify";
import { Link as RouterLink } from "react-router-dom";

// MUI Components
import { Typography } from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import Avatar from "@material-ui/core/Avatar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

// MUI Icons
import MyProfileIcon from "@material-ui/icons/AccountCircle";
import OrgIcon from "@material-ui/icons/Business";
import UsersIcon from "@material-ui/icons/PeopleAlt";
import SignOutIcon from "@material-ui/icons/ExitToApp";
import EmployeeDashboardIcon from "@material-ui/icons/HomeWork";

// Context
import MeContext from "contexts/MeContext";

// Util
import * as userUtil from "util/userUtil";

// Data
import ROLES from "enums/ROLES";

// Hooks
import usePageVisibility from "hooks/usePageVisibility";

// Services
import * as UserApi from "apis/UserApi";

// Styles
import { useStyles } from "./styles";

const DEFAULT_TIMEOUT = 1000 * 60;

export default function Badger(props) {
  const classes = useStyles();
  const me = useContext(MeContext);
  const [hasNotifications, setHasNotifications] = useState(false);
  const [randomNumber, setRandomNumber] = useState(Math.random());
  const timeout = useRef(DEFAULT_TIMEOUT);
  const visible = usePageVisibility();
  const { anchorEl = false, handleClose: propsHandleClose } = props;

  useEffect(() => {
    checkForNotifications();
  }, []);

  //exponential backoff to check for notifications
  useEffect(() => {
    const timer = setTimeout(() => {
      timeout.current = timeout.current * 2;
      setRandomNumber(Math.random());
      checkForNotifications();
    }, timeout.current);

    return () => clearTimeout(timer);
  }, [randomNumber]);

  //if the page isnt visible just wait and poll once per day
  useEffect(() => {
    if (visible) {
      timeout.current = DEFAULT_TIMEOUT;
      setRandomNumber(Math.random());
    } else {
      timeout.current = 1000 * 60 * 60 * 24;
      setRandomNumber(Math.random());
    }
  }, [visible]);

  const handleClose = () => {
    propsHandleClose();
  };

  async function handleSignOut() {
    await Auth.signOut();
  }

  async function checkForNotifications() {
    const tempNotifications = await UserApi.getNotifications();
    setHasNotifications(tempNotifications.length > 0);
  }

  return (
    <>
      {me && (
        <React.Fragment>
          <Badge
            color="secondary"
            variant="dot"
            overlap="circular"
            invisible={!hasNotifications}
          >
            <Avatar
              data-test="navBarAvatar"
              className={classes.avatar}
              alt={userUtil.getDisplayName(me)}
              src={me.picture}
            >
              {!me.picture ? userUtil.getInitials(me) : null}
            </Avatar>
          </Badge>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            color="primary"
            data-test="navBarMenu"
          >
            {/* <MenuItem
              component={RouterLink}
              to="/notifications"
              onClick={handleClose}
            >
              <Badge
                color="secondary"
                variant="dot"
                overlap="circular"
                invisible={!hasNotifications}
              >
                <NotificationsIcon></NotificationsIcon>
              </Badge>
              <Typography color="primary" className={classes.menuTitle}>
                Notifications
              </Typography>
            </MenuItem> */}
            <MenuItem
              component={RouterLink}
              to={userUtil.getProfileLink(me.userId)}
              onClick={handleClose}
            >
              <MyProfileIcon></MyProfileIcon>
              <Typography color="primary" className={classes.menuTitle}>
                My Profile
              </Typography>
            </MenuItem>

            <MenuItem
              component={RouterLink}
              to="/orgs"
              onClick={handleClose}
              hidden={userUtil.canDoApp(me, ROLES.READ_WRITE)}
            >
              <OrgIcon></OrgIcon>
              <Typography color="primary" className={classes.menuTitle}>
                Orgs
              </Typography>
            </MenuItem>

            <MenuItem onClick={handleSignOut} divider={userUtil.isEmployee(me)}>
              <SignOutIcon></SignOutIcon>
              <Typography
                color="secondary"
                data-test="signOut"
                className={classes.menuTitle}
              >
                Sign Out
              </Typography>
            </MenuItem>
            {userUtil.isEmployee(me) && (
              <MenuItem
                component={RouterLink}
                to="/employees"
                onClick={handleClose}
              >
                <EmployeeDashboardIcon color="primary"></EmployeeDashboardIcon>
                <Typography color="primary" className={classes.menuTitle}>
                  Employees
                </Typography>
              </MenuItem>
            )}
            {userUtil.isEmployee(me) && (
              <MenuItem
                component={RouterLink}
                to="/users"
                onClick={handleClose}
              >
                <UsersIcon color="primary"></UsersIcon>
                <Typography color="primary" className={classes.menuTitle}>
                  All Users
                </Typography>
              </MenuItem>
            )}
          </Menu>
        </React.Fragment>
      )}
    </>
  );
}
