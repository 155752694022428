// Packages
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  grid: {
    flexGrow: 1,
  },
  map: {
    padding: 0,
  },
  cardHeader: {
    backgroundColor: theme.palette.grey[200],
  },
  line1: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: "bold",
  },
  line2: {
    fontSize: theme.typography.pxToRem(16),
  },
  line3: {
    fontSize: theme.typography.pxToRem(16),
  },
  formattedAddress: {
    fontSize: theme.typography.pxToRem(16),
  },
  cardActions: {
    display: "flex",
    justifyContent: "flex-end",
  },
  title: {
    fontSize: 14,
  },
}));
