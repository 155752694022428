// Packages
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  accordionDetails: {
    backgroundColor: theme.palette.grey[100],
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  expanded: {
    "&$expanded": {
      margin: 0,
    },
  },
  asnwerImg: {
    maxWidth: "70%",
    marginTop: "30px",
  },
}));
