// Packages
import React from "react";

// MUI Components
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Grid from "@material-ui/core/Grid";

// Styles
import { useStyles } from "./styles";

export default function SummaryBar(props) {
  const classes = useStyles();
  const { title, expanded, children, bgColor, hideExpand } = props;
  const hasChildren = !!React.Children.toArray(children).length;

  return (
    <div>
      <Accordion
        className={classes.expansionPanel}
        style={{ backgroundColor: bgColor }}
        defaultExpanded={expanded}
        TransitionProps={{ unmountOnExit: true }}
      >
        <AccordionSummary
          expandIcon={
            (!hideExpand || hasChildren) && (
              <ExpandMoreIcon
                className={classes.expandMoreIcon}
                color="primary"
                data-test="expandMore"
              />
            )
          }
        >
          <Typography
            className={classes.heading}
            color="inherit"
            data-test="titlebar"
          >
            {title}
          </Typography>
        </AccordionSummary>
        { hasChildren && (<AccordionDetails
          className={classes.expansionPanelDetails}
          data-test="expansion-details"
        >
          <Grid container spacing={3} alignItems="stretch">
            {children}
          </Grid>
        </AccordionDetails>)}
      </Accordion>
    </div>
  );
}
