// Packages
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// MUI Components
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import LinearProgress from "@material-ui/core/LinearProgress";

// Services
import * as AssetApi from "apis/AssetApi";
import PACKAGE_TYPES from "enums/PACKAGE_TYPES";
import { fixMaterialTypeForAPI } from "util/commonUtil";


export default function DownloadMaterialsDialog(props) {
  const [loadingMessage, setLoadingMessage] = useState(
    "Please wait while we process your request..."
  );
  const [continueProcessing, setContinueProcessing] = useState(true);
  const [downloadUrl, setDownloadUrl] = useState(null);
  const navigate =  useNavigate();

  useEffect(() => {
    asyncDownloadWrapper();
  }, []);

  //analytics
  useEffect(() => {
    if (props.lineKey && props.states) {
      window.gtag("event", "package.download.states", {
        event_category: props.lineKey,
        event_label: JSON.stringify(props.states),
        value: props.states.length,
      });
      if(props.packageTypes){
        window.gtag("event", "package.download.packageTypes", {
          event_category: props.lineKey,
          event_label: JSON.stringify(props.packageTypes),
          value: props.packageTypes.length,
        });
      }
    }
  }, []);

  async function asyncDownloadWrapper() {
    let manifestId, zipFileName;
    if (continueProcessing) {
      manifestId = props.manifestId ? props.manifestId : await createManifest();
    }

    if (continueProcessing && manifestId) {
      zipFileName = await createZipFile(manifestId);
    }
    if(zipFileName === null || !zipFileName || zipFileName === undefined){
      const line = props.lineKey.length > 0 ? props.lineKey : ['All'];
      const url = `/lines/${line[0]}`;
      setDownloadUrl(url);

      setLoadingMessage(`Your content is being downloaded. If the download exceeds 30 seconds, the zip file will be available in the Recent Downloads section.`);
      setContinueProcessing(false);
    }

    if (continueProcessing && zipFileName) {
      downloadFile(zipFileName);
    }
  }

  function handleCancel() {
    setContinueProcessing(false);
    props.handleClose();
  }

  function handleCancelTop(){
    props.openDownloadDrawer();
    handleCancel();
  }

  function redirectToDownloads(){
    navigate(downloadUrl);
  }

  async function createManifest() {
    /*if (
      !Array.isArray(props.lineKey) ||
      props.lineKey.length <= 0
    ) {
      console.error(
        `lineKey [${props.lineKey}] required params not set correctly`
      );
      setLoadingMessage("Products are required to download");
      setContinueProcessing(false);
      return null;
    }*/
    //const deMuxedData = demuxMaterialTypes(props.packageTypes, props.publicationTypeCategory_query);
    //const fixedPackageTypes = deMuxedData[0];
    //const fixedSupportingPackageTypes = deMuxedData[1];

    const packageObjects = [];
    const fixedPackageTypesTop = props.packageTypes.map((type) => type.title);
    const fixedPackageTypesCategories = props.packageTypeCategory.map((key) => {
      for (const type in PACKAGE_TYPES) {
        if (PACKAGE_TYPES[type].publicationType === key) {
          packageObjects.push(PACKAGE_TYPES[type]);
          return PACKAGE_TYPES[type].title;
        }
      }
    });

    const fixedPackageTypes = fixMaterialTypeForAPI(fixedPackageTypesCategories, fixedPackageTypesTop, packageObjects);
    try {
      const manifest = await AssetApi.createManifestV2(
        props.lineKey,
        fixedPackageTypes,
        props.states,
        true,
        props.imgClass_s,
        fixedPackageTypesCategories,
        props.searchTerm,
        props.excludeFileContent,
        props.effectiveDate,
        props.effectiveOldestDate,
        props.plans,
      );
      console.log("NOTICE", manifest);
      if (manifest.name === "Error") {
        throw new Error(manifest.message);
      }
      if (manifest.files && manifest.files?.length === 0) {
        setLoadingMessage("There are no files to be delivered");
        setContinueProcessing(false);
        return;
      }
      return manifest.id;
    } catch (error) {
      props.setSnack({
        open: true,
        note: `${error.message}`,
        severity: "error",
      });
      handleCancel();
      return null;
    }
  }

  async function createZipFile(manifestId) {
    try{
      const zipFileName = await AssetApi.createZipFileV1(manifestId).catch((error) => {
        return null;
      });
      return zipFileName;
    }catch(error){
      return null;
    }
  }

  function downloadFile(zipFileName) {
    const prefixUrl = process.env.REACT_APP_MSS_PUBLIC_TEMP_CACHE_URL;
    const url = `${prefixUrl}/${zipFileName}`;
    window.open(url, "_parent");
    props.openDownloadDrawer();
    props.handleClose();
  }

  return (
    <Dialog
      open={true}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      disableEscapeKeyDown
      maxWidth="md"
    >
      <DialogTitle id="alert-dialog-title">Downloading Content</DialogTitle>
      <DialogContent>
        {continueProcessing && <LinearProgress />}
        <DialogContentText id="alert-dialog-description">
          {loadingMessage}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Dismiss
        </Button>
        {downloadUrl != null && 
          <Button onClick={handleCancelTop} color="primary">
            OK
          </Button>
        }
      </DialogActions>
    </Dialog>
  );
}
