// Packages
import React, { useState, useEffect } from "react";

// MUI Components
import Grid from "@material-ui/core/Grid";
import { Container } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";

// Components
import TransactionItem from "components/TransactionItem";

// Styles
import { useStyles } from "./styles";

// Services
import * as OrgApi from "apis/OrgApi";

export default function Transactions(props) {
  const classes = useStyles();
  const { orgId } = props;
  const [loading, setLoading] = React.useState(true);
  const [transactions, setTransactions] = useState();

  useEffect(() => {
    OrgApi.getTransactions(orgId).then((results) => {
      setLoading(false);
      if (results && results.data) {
        console.log(results.data);
        setTransactions(results.data);
      }
    });
  }, [orgId]);
  return (
    <React.Fragment>
      {loading && <LinearProgress />}
      <Container maxWidth="sm">
        <Grid
          container
          direction="column"
          alignItems="stretch"
          spacing={2}
          className={classes.grid}
        >
          {transactions &&
            transactions.map((transaction) => {
              return (
                <Grid key={transaction.transactionId} item xs={12}>
                  <TransactionItem transaction={transaction} />
                </Grid>
              );
            })}
        </Grid>
      </Container>
    </React.Fragment>
  );
}
