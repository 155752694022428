// Packages
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  summaryBar: {
    display: "flex",
    background: theme.palette.primary.main,
    paddingLeft: "15px",
    fontSize: "22px",
    color: "white",
    height: "56.125px",
    alignItems: "center"
  },
  buttonBar: {
    display: "flex",
    marginTop: "20px"
  },
  cardGrid: {
    display: "flex",
    marginTop: "20px",
  }
}));
