// Packages
import React, { useState } from "react";

// MUI Components
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function InviteUserDialog(props) {
  const [email, setEmail] = useState("");

  return (
    <Dialog
      maxWidth="sm"
      fullWidth={true}
      open={true}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Enter email</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          data-test="email-input"
          margin="dense"
          id="email"
          label="Email"
          value={email}
          placeholder="john.doe@example.com"
          type="email"
          fullWidth
          onChange={(event) => setEmail(event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => props.handleCreate(email)}
          color="primary"
          disabled={!email || email.length <= 3 || !email.includes("@")}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
