/**
 * @description ProductApi Module. This will have all the API's needed
 * to interact with the backend API's for the Products
 * @module apis/AssetApi
 * @since 1.0.0
 * @author David Ganz <davidg@aaisonline.com>
 * @public
 */

import { API } from "aws-amplify";
import { sortOrgs as sortProducts } from "../util/orgUtil";
import * as commonUtil from "../util/commonUtil";


/**
 * @author David Ganz
 *
 * @description Allows the querying of the Product/Products
 *
 * @param productId pass the unique identifier for this Product
 * if you are trying to get a specific Product. If you would like
 * all the Product just call this method with
 * the `productId` set it to undefined
 * @example
 * get("productId123"); //will return the specific product
 *
 * @returns a particular product if a `productId` is passed in
 *
 */
export async function get(productId) {
  if (!productId) return null;

  let product;
  const path = `/products/${productId}`;

  try {
    product = await API.get("PRODUCT", path);
    //storeProductInCache(product); //TODO: consider FULL implementation of caching
  } catch (error) {
    console.log(error);
    return error;
  }

  return product;
}

export async function getProducts(productIds) {
  if (!Array.isArray(productIds)) return [];

  //convert array to comma separated list
  const productIdsString = commonUtil.filterAndJoinResults(productIds);

  if (!productIdsString) return [];

  const path = `/products?productIds=${productIdsString}`;

  try {
    const products = await API.get("PRODUCT", path);
    //storeProductsInCache(products); //TODO: consider FULL implementation of caching
    return sortProducts(products);
  } catch (error) {
    console.log(error);
    throw error.friendlyErrorMessage ? error.friendlyErrorMessage : error;
  }
}

/* TODO: consider FULL implementation of caching
export function getProductsFromCache(productIds) {
  if (!productIds || !Array.isArray(productIds)) return;

  const products = [];
  productIds.forEach((productId) => {
    const product = getFromCache(productId);
    if (product) products.push(product);
  });

  return sortProducts(products);
}
*/

export async function getAll() {
  try {
    return await API.get("PRODUCT", "/products");
  } catch (error) {
    console.error("an error occurred", error);
    return error;
  }
}

/**
 * @description Creates an product
 * @param {string} name The name of the product you are creating
 * @returns {object} a created product object
 */
export async function create(name) {
  if (!name) return null;

  const path = `/products`;
  const body = { name };

  const init = {
    body,
  };
  try {
    return await API.post("PRODUCT", path, init);
  } catch (error) {
    console.log(error);
    return error;
  }
}

/**
 * @description Updates an product name
 * @param {string} key The name of the landingPage you are updating
 * @param {string} value The name of the landingPage you are updating
 * @returns {object} a created product object
 */
export async function update(productId, key, value) {
  if (!productId || !key || !value) return null;

  const path = `/products/${productId}`;
  const body = { key: key, value: value };

  const init = {
    body: body,
  };

  try {
    return await API.put("PRODUCT", path, init);
  } catch (error) {
    console.log(error);
    return error;
  }
}


/**
 * @author David Ganz <davidg@aaisonline.com>
 *
 * @description Get transactions for a specific product
 *
 * @param {string} productId pass the unique identifier of the product
 * the `productId` set it to undefined
 * @param {object} [exclusiveStartKey] used to paginate the results. use the
 * `lastEvaluatedKey` from the previous query to get the next batch of data
 * @example
 * get("productId123"); //will return the specific product transactions
 *
 * @returns a particular product if a `productId` is passed in
 *
 * @throws {NotAuthorizedException} Trying to access a partiular User
 * when the caller is not authorized
 */
export async function getTransactions(productId, exclusiveStartKey = undefined) {
  if (!productId) return null;

  let transactions;
  const path = `/products/${productId}/transactions`;
  const init = {
    body: { exclusiveStartKey },
  };

  try {
    transactions = await API.post("PRODUCT", path, init);
  } catch (error) {
    console.log(error);
    return error;
  }

  return transactions;
}

/**
 * @description Gets a copy of a specific product from local storage.
 * This helps with optimization so that we can fetch from cache first before going
 * to the REST API's
 * @private
 * @author David Ganz <davidg@aaislonline.com>
 * @param {*} productId the unique productId for the product to get
 * @since 1.0.0
 */

/* TODO: consider FULL implementation of caching
export function getFromCache(productId) {
  if (!productId) return;

  const productJSON = localStorage.getItem(productId);

  try {
    return JSON.parse(productJSON);
  } catch (error) {
    console.error(`could not parse product object ${productJSON}`, error);
  }

  return null;
}
*/

/*
###############################################################################
                                PRIVATE APIS
                  DO NOT CALL THESE FROM OUTSIDE THIS MODULE
###############################################################################
*/

/**
 * @description Sets a copy of a specific product to local storage.
 * This helps with optimization so that we can fetch from cache first before going
 * to the REST API's
 * @private
 * @author David Ganz <davidg@aaisonline.com>
 * @param {*} productId the unique productId for the product to get
 * @since 1.0.0
 */

/*
TODO: consider FULL implementation of caching

function storeProductInCache(product) {
  if (!product || !product.productId) return;
  localStorage.setItem(product.productId, JSON.stringify(product));
}

function storeProductsInCache(products) {
  if (!products || !Array.isArray(products)) return;

  products.forEach((product) => storeProductInCache(product));
}
*/

/*
###############################################################################
                                END PRIVATE APIS
                  DO NOT CALL THESE FROM OUTSIDE THIS MODULE
###############################################################################
*/
