import React, { useState, useEffect } from "react";

// MUI Components
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { Container, Card, CardContent } from "@material-ui/core";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowUpIcon from '@material-ui/icons/ArrowDropUp';

// Styles
import { useStyles } from "./styles";

// Data
import PACKAGE_TYPES from "enums/PACKAGE_TYPES";

export default function PackageTypesInfo(props) {
    const classes = useStyles();
    const [data, setData] = useState();


    function setUpPackage(value){        
        //const fixedValue = value.replace(/\s/g, "_").toUpperCase();
        let packageObject = null;
        if(value === 'Form'){
            const type = 'FORMS';
            props.setPackageTypeCategory([PACKAGE_TYPES[type].publicationType]);
            return;
        }//special case just for Forms.
        if(value === 'Statistical Plan'){
            const type = 'STATISTICAL_PLANS';
            props.setPackageTypeCategory([PACKAGE_TYPES[type].publicationType]);
            return;
        }//special case just for STATISTICAL_PLANS.
        if(value === 'Compliance Guide'){
            const type = 'COMPLIANCE';
            props.setPackageTypeCategory([PACKAGE_TYPES[type].publicationType]);
            return;
        }//special case just for COMPLIANCE.
        for (const type in PACKAGE_TYPES) {            
            if(PACKAGE_TYPES[type].types){
                const subTypes = Object.values(PACKAGE_TYPES[type].types);
                for (const subTypeI in subTypes){
                    const subType = subTypes[subTypeI];
                    if(value === subType.title){
                        props.setPackageTypeCategory([PACKAGE_TYPES[type].publicationType]);//publicationTypeCategory_query
                        packageObject = subType;
                        props.setPackageType([subType]);//value
                    }
                }
            }
        }
    }
    useEffect(() => {
        if(props.aggs){
            if('publicationTypeCategories' in props.aggs){
                const allDataTypes = props.aggs['publicationTypes']['buckets'];
                allDataTypes.sort((a, b) => {
                    const nameA=a.key.toLowerCase(), nameB=b.key.toLowerCase();
                    if (nameA < nameB){
                        return -1;
                    }
                    if (nameA > nameB){
                        return 1;
                    }
                    return 0;
                  });
                const thisData = <React.Fragment>
                    <List className={classes.list}>
                    {allDataTypes.map( (item, i) => {
                            return <ListItem key={item.key} onClick={() => setUpPackage(item.key)} className={classes.listItemPackageTypesInfo}><span><b>{item.key}: </b> &nbsp; {item.doc_count.toLocaleString()}</span></ListItem>;
                        })}
                    </List>
                </React.Fragment>;
                setData(thisData);
            }
        }
    }, [props.aggs]);  

    return (
        <React.Fragment>
            <Container disableGutters className={classes.container}>
                <Card elevation={5}>
                    <CardContent className={classes.downloadCard} onClick={() => props.setShowPackagesInfo(!props.showPackagesInfo)}>
                        <span className={classes.leftDownloadContent}>Material SubTypes</span>
                        {!props.showDownloads &&
                            <ArrowDropDownIcon className={classes.menuItemA}></ArrowDropDownIcon>
                        }
                        {props.showDownloads &&
                            <ArrowUpIcon className={classes.menuItemB}></ArrowUpIcon>
                        }
                    </CardContent>
                    {props.showPackagesInfo && (
                        <div>{data}</div>
                    )}
                </Card>
            </Container>
        </React.Fragment>
    );
}