// Packages
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  list: {
    flexGrow: 1,
  },
  cardHeader: {
    backgroundColor: "#ececfa",
  },
  cardContent: {
    maxHeight: 280,
    overflowY: "auto",
  },
}));
