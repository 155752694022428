// Packages
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  grid: {
    marginTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
  },
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(1),
  },
  lineDescription: {
    padding: theme.spacing(4),
  },
  certifiedChip: {
    backgroundColor: "#03960e",
    color: "white",
    marginLeft: 10,
  },
  certifyingChip: {
    backgroundColor: "#f7f4a4f0",
    color: "black",
    marginLeft: 10,
  },
  notCertifiedChip: {
    backgroundColor: "white",
    color: "black",
    marginLeft: 10,
  },
}));
