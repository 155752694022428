// Packages
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  productModalContainer: {
    height: "90%",
    fontSize: "1em",
  },
  container: {
    marginTop: theme.spacing(2),
    height: "100%",
    width: "100%",
  },
  drawer: {
    //maxWidth: 280,
  },
}));
