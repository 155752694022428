// Packages
import React, { useState } from "react";

// Components
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Autocomplete from "@material-ui/lab/Autocomplete";

// Styles
import { useStyles } from "./styles";

// Services
import * as UserApi from "apis/UserApi";

export default function PickAUserDialog(props) {
  const { userIds } = props;
  const classes = useStyles();

  const [users, setUsers] = useState(UserApi.getUsersFromCache(userIds));
  const [selectedUser, setSelectedUser] = useState(null);

  return (
    <Dialog
      maxWidth="sm"
      fullWidth={true}
      open={true}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
      data-testid="pickAUserDialog" // FIXME: Deperecates data-testid
      data-test="pickAUserDialog"
      className={classes.root}
    >
      <DialogTitle id="form-dialog-title">Find User</DialogTitle>
      {users && (
        <DialogContent>
          <Autocomplete
            options={users}
            fullWidth={true}
            data-test="pickUser"
            getOptionLabel={(user) => user.email}
            renderInput={(params) => (
              <TextField {...params} label="Select a user" variant="outlined" />
            )}
            onChange={(event, value) => setSelectedUser(value)}
          />
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => props.handleSelectUser(selectedUser)}
          color="primary"
          variant="contained"
          disabled={!selectedUser}
        >
          Select user
        </Button>
      </DialogActions>
    </Dialog>
  );
}
