// Packages
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  root: {
    width: "100%",
    borderTop: "1px solid #204286",
    position: "fixed",
    bottom: 0,
  },
  link: {
    maxWidth: "auto",
    minWidth: "auto",
  },
});
