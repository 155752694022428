/**
 * @description This is the Contact Section component that is used
 * throught the application. It is used to quickly org a list
 * of contacts based on a particular contact type. It has the ability to
 * add a contact with its built in pick a user dialog
 * @author Findlay Clarke <findlayc@aaisonline.com>
 * @since 1.0.0
 * @module components/ContactSection
 */

// Packages
import React, { useContext } from "react";

// MUI Components
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import LinearProgress from "@material-ui/core/LinearProgress";
import Snackbar from "@material-ui/core/Snackbar";

// Components
import PickAUserDialog from "components/PickAUserDialog";
import UserCard from "components/UserCard";

// Context
import OrgContext from "contexts/OrgContext";
import MeContext from "contexts/MeContext";

// Util
import * as userUtil from "util/userUtil";
import * as orgUtil from "util/orgUtil";

// Data
import ROLES from "enums/ROLES";

// Styles
import { useStyles } from "./styles";

export default function ContactSection(props) {
  const { userIds } = props;
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [snack, setSnack] = React.useState({ open: false, note: null });

  const me = useContext(MeContext);
  const org = useContext(OrgContext);

  function handleShowDialog() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  function getContactsCards() {
    if (!userIds) return;

    return userIds.map((userId, index) => {
      return (
        <UserCard
          key={userId}
          userId={userId}
          handleRemove={handleRemove}
          index={index}
        />
      );
    });
  }

  async function handleAdd(user) {
    setLoading(true);
    setOpen(false);
    await props.handleAddContact(user, props.contactType);
    setLoading(false);
    setSnack({
      open: true,
      note: `${userUtil.getDisplayName(user)} added as a ${
        props.contactType.title
      } contact`,
    });
  }

  async function handleRemove(user) {
    setLoading(true);
    await props.handleRemoveContact(user, props.contactType);
    setLoading(false);
    setSnack({
      open: true,
      note: `${userUtil.getDisplayName(user)} removed as a ${
        props.contactType.title
      } contact`,
    });
  }

  return (
    <React.Fragment>
      {loading && <LinearProgress />}
      {
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={snack.open}
          onClose={() => setSnack({ open: false, note: null })}
          autoHideDuration={5000}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span>{snack.note}</span>}
        />
      }
      <Paper className={classes.root} elevation={1}>
        <Grid
          container
          data-test={"Add-" + props.contactType.title}
          alignItems="flex-start"
          justifyContent="space-between"
          className={classes.topSection}
        >
          <Grid item>
            <Typography variant="h5" component="h4">
              {props.contactType.title}
            </Typography>
          </Grid>
          <Grid item>
            {userUtil.canDoOrg(me, ROLES.READ_WRITE, org) && (
              <Fab
                size="small"
                color="primary"
                aria-label="Add"
                data-test="Add"
                onClick={handleShowDialog}
              >
                <AddIcon />
              </Fab>
            )}
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          alignItems="stretch"
          data-test={props.contactType.title}
        >
          {getContactsCards()}
        </Grid>
        {open && (
          <PickAUserDialog
            userIds={orgUtil.getUserIds(org)}
            handleClose={handleClose}
            handleSelectUser={handleAdd}
          />
        )}
      </Paper>
    </React.Fragment>
  );
}
