/**
 * @description The enumeration of the Package types
 * @since 1.0.0
 * @enum {String}
 * @readonly
 * @public
 */
const PACKAGE_TYPES = {
  /**
   * @constant {String}
   * @description Advisory Information
   * @since 1.0.0
   */
  ADVISORY_INFO: {
    key: "ADVISORY_INFO",
    title: "Advisory Information",
    description: "The Advisory Information",
    publicationType: "ADV",
    types: {
      /**
       * @constant {object}
       * @since 1.0.0
       */
      DECLARATIONS: {
        key: "Declarations",
        title: "Declarations",
        description: "Declarations",
        publicationTypeCategory_query: "DEC",
      },
      
      HOMEOWNERS_ADVISORY_INFO: {
        key: "HOMEOWNERS_ADVISORY_INFO",
        title: "Homeowners Advisory Information",
        description: "HOMEOWNERS_ADVISORY_INFO",
        publicationTypeCategory_query: "HAI",
      }, 
      HOMEOWNERS_SAMPLE_ENDORSEMENTS: {
        key: "HOMEOWNERS_SAMPLE_ENDORSEMENTS",
        title: "Homeowners Sample Endorsements",
        description: "HOMEOWNERS_SAMPLE_ENDORSEMENTS",
        publicationTypeCategory_query: "HSE",
      }, 
      MISC: {
        key: "MISC",
        title: "Misc",
        description: "MISC",
        publicationTypeCategory_query: "MIC",
      }, 
      SAMPLE_DISCLOSURE_NOTICE: {
        key: "SAMPLE_DISCLOSURE_NOTICE",
        title: "Sample Disclosure Notice",
        description: "SAMPLE_DISCLOSURE_NOTICE",
        publicationTypeCategory_query: "SDN",
      }, 
      SAMPLE_ENDORSEMENTS: {
        key: "SAMPLE_ENDORSEMENTS",
        title: "Sample Endorsements",
        description: "SAMPLE_ENDORSEMENTS",
        publicationTypeCategory_query: "SE",
      }, 
      SCHEDULE_COVERAGE: {
        key: "SCHEDULE_COVERAGE",
        title: "Schedule Coverage",
        description: "SCHEDULE_COVERAGE",
        publicationTypeCategory_query: "SCC",
      }, 
      UNDERLYING_INSURANCE: {
        key: "UNDERLYING_INSURANCE",
        title: "Underlying Insurance",
        description: "UNDERLYING_INSURANCE",
        publicationTypeCategory_query: "UDI",
      }, 
    },
  },
  /**
   * @constant {String}
   * @description The bulletins
   * @since 1.0.0
   */
  BULLETINS: {
    key: "BULLETINS",
    title: "Bulletins",
    description: "The Bulletins",
    publicationType: "BUL",
    types: {
      /**
       * @constant {object}
       * @since 1.0.0
       */
      COUNTRYWIDE: {
        key: "COUNTRYWIDE",
        title: "Countrywide Bulletin",
        description: "COUNTRYWIDE",
        publicationTypeCategory_query: "CNW",
      }, 
      GENERAL: {
        key: "GENERAL",
        title: "General Bulletin",
        description: "GENERAL",
        publicationTypeCategory_query: "GEN",
      },  
      STATE: {
        key: "STATE",
        title: "State-specific Bulletin",
        description: "STATE",
        publicationTypeCategory_query: "STT",
      },  
      STATISTICAL: {
        key: "STATISTICAL",
        title: "Statistical Bulletin",
        description: "STATISTICAL",
        publicationTypeCategory_query: "STA",
      },     
    },
  },
  /**
   * @constant {String}
   * @description The Compliance Guide
   * @since 1.0.0
   */
  COMPLIANCE: {
    key: "COMPLIANCE",
    title: "Compliance Guide",
    description: "Compliance Guide",
    publicationType: "CMP",
  },
  /**
   * @constant {String}
   * @description The Education Materials
   * @since 1.0.0
   */
  EDUCATION_MATERIALS: {
    key: "EDUCATION_MATERIALS",
    title: "Education Materials",
    description: "Education Materials",
    publicationType: "EDM",
    types: {
      /**
       * @constant {object}
       * @since 1.0.0
       */
      FAQ:{
        key: "FAQ",
        title: "FAQs",
        description: "FAQs",
        publicationTypeCategory_query: "FAQ",
      },
      PROGRAM_MATERIALS: {
        key: "PROGRAM_MATERIALS",
        title: "Program Materials",
        description: "Program Materials",
        publicationTypeCategory_query: "PMM",
      }, 
      REF_ART: {
        key: "REF_ART",
        title: "Reference Article",
        description: "Reference Article",
        publicationTypeCategory_query: "RAT",
      },  
      REF_MAT: {
        key: "REF_MAT",
        title: "Reference Material",
        description: "Reference Material",
        publicationTypeCategory_query: "RMT",
      },  
      SIDE: {
        key: "SIDE",
        title: "Side by Side Comparison",
        description: "Side by Side Comparison",
        publicationTypeCategory_query: "SID",
      },     
    },
  },
  /**
   * @constant {String}
   * @description The mailing address
   * @since 1.0.0
   */
  FORMS: {
    key: "FORMS",
    title: "Forms",
    description: "The forms",
    publicationType: "PFM",
  },
  /**
   * @constant {String}
   * @description The Forms And Endorsements Lists
   * @since 1.0.0
   */
  FORMS_AND_ENDORSEMENTS_LISTS: {
    key: "FORMS_AND_ENDORSEMENTS_LISTS",
    title: "Forms and Endorsements Lists",
    description: "Forms and Endorsements Lists",
    publicationType: "FAL",
    types: {
      /**
       * @constant {object}
       * @since 1.0.0
       */
      FEL: {
        key: "FEL",
        title: "Forms and Endorsements List",
        description: "Forms and Endorsements List",
        publicationTypeCategory_query: "FEL",
      }, 
      MULTI_STATE_FORMS_AND_ENDORSEMENTS_LIST: {
        key: "MULTI_STATE_FORMS_AND_ENDORSEMENTS_LIST",
        title: "Multistate Forms and Endorsements List",
        description: "Multi State Forms and Endorsements",
        publicationTypeCategory_query: "MFE",
      },    
    },
  },
  IMG_PUBLICATIONS: {
    key: "IMG_PUBLICATIONS",
    title: "IMG Publications",
    description: "The supporting documents for Inland Marine Guide",
    publicationType: "IMS",
    types: {
      COMPATIBLE_SCHEDULES_AND_ENDORSEMENTS: {
        key: "COMPATIBLE_SCHEDULES_AND_ENDORSEMENTS",
        title: "Compatible Schedules / Endorsements",
        description: "Compatible Schedules and Endorsements",
        publicationTypeCategory_query: "CSE",
      },
      COMPLETED_VALUE_REPORT: {
        key: "COMPLETED_VALUE_REPORT",
        title: "Completed Value Report",
        description: "Completed Value Report",
        publicationTypeCategory_query: "CVR",
      },
      COVERAGE: {
        key: "COVERAGE",
        title: "Coverage",
        description: "Coverage",
        publicationTypeCategory_query: "CVC",
      },
       DISCLOSURE_NOTICES: {
        key: "DISCLOSURE_NOTICES",
        title: "Disclosure Notices",
        description: "DISCLOSURE_NOTICES",
        publicationTypeCategory_query: "DSN",
      },
       EXCEL_SCHEDULES: {
        key: "EXCEL_SCHEDULES",
        title: "Excel Schedules",
        description: "EXCEL_SCHEDULES",
        publicationTypeCategory_query: "EXS",
      },
      FILING_INFORMATION: {
        key: "FILING_INFORMATION",
        title: "Filing Information",
        description: "",
        publicationTypeCategory_query: "FIN",
      },
      FORMS_COMPARISON: {
        key: "FORMS_COMPARISON",
        title: "Forms Comparison",
        description: "",
        publicationTypeCategory_query: "FIC",
      },
      GENERAL_ENDORSEMENTS_AND_COMPATIBLE_FORMS: {
        key: "GENERAL_ENDORSEMENTS_AND_COMPATIBLE_FORMS",
        title: "General Endorsements And Compatible Forms",
        description: "General Endorsements And Compatible Forms",
        publicationTypeCategory_query: "GEC",
      },
      GENERAL_INFORMATION: {
        key: "GENERAL_INFORMATION",
        title: "General Information",
        description: "General Information",
        publicationTypeCategory_query: "GIN",
      },
      INFORMATION: {
        key: "INFORMATION",
        title: "Information",
        description: "Information",
        publicationTypeCategory_query: "INF",
      },
      IRPM: {
        key: "IRPM",
        title: "IRPM",
        description: "IRPM",
        publicationTypeCategory_query: "IRPM",
      },
      LEGAL_BRIEFS: {
        key: "LEGAL_BRIEFS",
        title: "Legal Briefs",
        description: "Legal Briefs",
        publicationTypeCategory_query: "LGB",
      },
      LOSS_CONTROL_SURVEY: {
        key: "LOSS_CONTROL_SURVEY",
        title: "Loss Control Survey",
        description: "Loss Control Survey",
        publicationTypeCategory_query: "LCS",
      },
      MARKETING_MATERIALS: {
        key: "MARKETING_MATERIALS",
        title: "Marketing Materials",
        description: "Marketing Materials",
        publicationTypeCategory_query: "MKM",
      },
      NARRATED_PRESENTATIONS: {
        key: "NARRATED_PRESENTATIONS",
        title: "Narrated Presentations",
        description: "Narrated Presentations",
        publicationTypeCategory_query: "NRP",
      },
      RATING: {
        key: "RATING",
        title: "Rating",
        description: "Rating",
        publicationTypeCategory_query: "RTG",
      },
      RATING_WORKSHEET: {
        key: "RATING_WORKSHEET",
        title: "Rating Worksheet",
        description: "Rating Worksheet",
        publicationTypeCategory_query: "RTW",
      },
      REFERENCE_MATERIALS: {
        key: "REFERENCE_MATERIALS",
        title: "Reference Materials",
        description: "Reference Materials",
        publicationTypeCategory_query: "RFM",
      },
      RULES: {
        key: "RULES",
        title: "Rules",
        description: "Rules",
        publicationTypeCategory_query: "RLE",
      },
      SIDE_BY_SIDES: {
        key: "SIDE_BY_SIDES",
        title: "Side-by-Sides",
        description: "Side-by-Sides",
        publicationTypeCategory_query: "SBS",
      },
      SUBLIMITS: {
        key: "SUBLIMITS",
        title: "Sublimits",
        description: "Sublimits",
        publicationTypeCategory_query: "SBL",
      },
      TERRORISM_RATING_INFORMATION_NSFP_FILED: {
        key: "TERRORISM_RATING_INFORMATION_NSFP_FILED",
        title: "Terrorism Rating Information (NSFP - Filed)",
        description: "Terrorism Rating Information (NSFP - Filed)",
        publicationTypeCategory_query: "TRINF",
      },
      TERRORISM_RATING_INFORMATION_NSFP_NOT_FILED: {
        key: "TERRORISM_RATING_INFORMATION_NSFP_NOT_FILED",
        title: "Terrorism Rating Information (NSFP - Not-Filed)",
        description: "Terrorism Rating Information (NSFP - Not-Filed)",
        publicationTypeCategory_query: "REINN",
      },
      TERRORISM_RATING_INFORMATION_SFP_FILED: {
        key: "TERRORISM_RATING_INFORMATION_SFP_FILED",
        title: "Terrorism Rating Information (SFP - Filed)",
        description: "Terrorism Rating Information (SFP - Filed)",
        publicationTypeCategory_query: "TRISF",
      },
      TERRORISM_RATING_INFORMATION_SFP_NOT_FILED: {
        key: "TERRORISM_RATING_INFORMATION_SFP_NOT_FILED",
        title: "Terrorism Rating Information (SFP - Not-Filed)",
        description: "Terrorism Rating Information (SFP - Not-Filed)",
        publicationTypeCategory_query: "TRISN",
      },
      TERRORISM_RULES_MULTISTATE: {
        key: "TERRORISM_RULES_MULTISTATE",
        title: "Terrorism Rules - Multistate",
        description: "Terrorism Rules - Multistate",
        publicationTypeCategory_query: "TRM",
      },
      TERRORISM_RULES_STATE_SPECIFIC: {
        key: "TERRORISM_RULES_STATE_SPECIFIC",
        title: "Terrorism Rules - State-Specific",
        description: "Terrorism Rules - State-Specific",
        publicationTypeCategory_query: "TRS",
      },
      UNDERWRITING: {
        key: "UNDERWRITING",
        title: "Underwriting",
        description: "Underwriting",
        publicationTypeCategory_query: "UND",
      },
    },
  },
  /**
   * @constant {String}
   * @description The loss cost
   * @since 1.0.0
   */
  MANUAL_MATERIALS: {
    key: "MANUAL_MATERIALS",
    title: "Manual Materials",
    description: "The MANUAL_MATERIALS",
    publicationType: "MNM",
    types: {
      /**
       * @constant {object}
       * @since 1.0.0
       */
      DATA_SET: {
        key: "DATA_SET",
        title: "Data Set",
        description: "DATA_SET",
        publicationTypeCategory_query: "DTS",
      },
      /**
       * @constant {object}
       * @since 1.0.0
       */
      DOWNLOAD_FOR_MICROSOFT_OFFICE: {
        key: "DOWNLOAD_FOR_MICROSOFT_OFFICE",
        title: "Download For Microsoft Office",
        description: "DOWNLOAD_FOR_MICROSOFT_OFFICE",
        publicationTypeCategory_query: "DMO",
      },
      /**
       * @constant {object}
       * @since 1.0.0
       */
      MULTI_AND_STATE_SPECIFIC_MANUALS: {
        key: "MULTI_AND_STATE_SPECIFIC_MANUALS",
        title: "Multi & State-specific Manuals",
        description: "MULTI_AND_STATE_SPECIFIC_MANUALS",
        publicationTypeCategory_query: "MSSM",
      },
      /**
       * @constant {object}
       * @since 1.0.0
       */
      STATE_PAGES: {
        key: "STATE_PAGES",
        title: "State Pages",
        description: "STATE_PAGES",
        publicationTypeCategory_query: "STPE",
      },
      /**
       * @constant {object}
       * @since 1.0.0
       */
      SUPPLEMENT: {
        key: "SUPPLEMENT",
        title: "Supplement",
        description: "SUPPLEMENT",
        publicationTypeCategory_query: "SUP",
      },
    }
  },
  /**
   * @constant {String}
   * @description The reports
   * @since 1.0.0
   */
  REPORTS: {
    key: "REPORTS",
    title: "Reports",
    description: "The REPORTS",
    publicationType: "RPT",
    types: {
      /**
       * @constant {object}
       * @since 1.0.0
       */
      CAUSE_OF_LOSS_REPORT: {
        key: "CAUSE_OF_LOSS_REPORT",
        title: "Cause of Loss Report",
        description: "Cause of Loss Report",
        publicationTypeCategory_query: "CLR",
      },
      /**
       * @constant {object}
       * @since 1.0.0
       */
      STATUS_REPORT: {
        key: "STATUS_REPORT",
        title: "Status Report",
        description: "Status Report",
        publicationTypeCategory_query: "SRT",
      },
    }
  },
  /**
   * @constant {String}
   * @description The STATISTICAL_PLANS
   * @since 1.0.0
   */
  STATISTICAL_PLANS: {
    key: "STATISTICAL_PLANS",
    title: "Statistical Plans",
    description: "Statistical Plans",
    publicationType: "STP",
  },
};
export default PACKAGE_TYPES;
