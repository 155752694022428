// Packages
import React, { useState, useEffect } from "react";

// MUI Components
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import LinearProgress from "@material-ui/core/LinearProgress";

// Services
import * as AssetApi from "apis/AssetApi";


export default function DownloadDocumentDialog(props) {
  const [loadingMessage, setLoadingMessage] = useState(
    "Please wait while we process your request..."
  );
  const [continueProcessing, setContinueProcessing] = useState(true);

  useEffect(() => {
    asyncDownloadWrapper();
  }, []);


  async function asyncDownloadWrapper() {
    let zipFileName;
    if (continueProcessing && props.filesArr) {
      zipFileName = await getDownloads(props.filesArr);
    }
    if(zipFileName == null){

      setLoadingMessage(`Something went wrong, please try again later`);
      setContinueProcessing(false);
    }

    if (continueProcessing && zipFileName) {
      downloadFile(zipFileName);
    }
  }

  function handleCancel() {
    setContinueProcessing(false);
    props.handleClose();
  }


  async function getDownloads(filesArr) {
    try{
      const zipFileName = await AssetApi.download(filesArr).catch((error) => {
        return null;
      });
      return zipFileName;
    }catch(error){
      return null;
    }
  }

  function downloadFile(zipFileName) {
    const prefixUrl = process.env.REACT_APP_MSS_PUBLIC_TEMP_CACHE_URL;
    const url = `${prefixUrl}/${zipFileName}`;
    window.open(url, "_blank", 'popup=false,noopener');
    props.handleClose();
  }

  return (
    <Dialog
      open={true}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      disableEscapeKeyDown
      maxWidth="md"
    >
      <DialogTitle id="alert-dialog-title">Downloading Content</DialogTitle>
      <DialogContent>
        {continueProcessing && <LinearProgress />}
        <DialogContentText id="alert-dialog-description">
          {loadingMessage}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Dismiss
        </Button>
      </DialogActions>
    </Dialog>
  );
}
