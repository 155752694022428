// Packages
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  tooltip: {
    fontSize: theme.typography.pxToRem(15),
  },
  loadingRow: {
    padding: 0,
    height: 0,
  },
  loadingCell: {
    padding: 0,
  },
  roleKeySelector: {
    minWidth: 125,
  },
}));
