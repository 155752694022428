// Packages
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  title: {
    fontSize: 24,
  },
  root: {
    marginBottom: theme.spacing(8),
  },
  grid: {
    marginTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
  },
  welcome: {
    paddingTop: 20,
    height: "calc(100vh - 141px)",
    textAlign: "center",
  },
  dashboard: {
    paddingTop: theme.spacing(2),
  },
  link: {
    textDecoration: "none !important",
    cursor: "pointer",
    transition: "color 0.2s",
    "&:hover": {
        color: "#0097AC"
    }
  }
}));
