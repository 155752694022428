// Packages
import React, { useState, useEffect } from "react";

// Services
import * as AssetApi from "apis/AssetApi";

import { useStyles } from "./styles";


export default function ZipStatus(props) {
    const classes = useStyles();
    const [manifestId, setManifestId] = useState(props.manifestId);
    const [zipStatus, setZipStatus] = useState(-1);

    useEffect(() => {
        getZipStatus(manifestId);
      }, [props.manifestId]);

    async function getZipStatus(maifestId){
        await AssetApi.checkZipFileV1(maifestId).then( (data) => {
            if(String(data) === 'true'){
                setZipStatus(1);
            }
            else{
                setZipStatus(0);
            }
        });
    
        return null;
    }

    return (
        <React.Fragment>
            {  zipStatus === 1 &&
                'File: Available'
            }
            {  zipStatus === -1 && 
                'File: Processing'
            }
            { (zipStatus !== 1 && zipStatus !== -1) &&
                "File: Failed"//"File: Not available"
            }
        </React.Fragment>
  );
}