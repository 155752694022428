// Packages
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  grid: {
    marginTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
  },
  left: {
    paddingRight: 10,
  },
  right: {
    paddingLeft: 10,
  },
  paper: {
    padding: theme.spacing(1),
  },
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
  searchText: {
    width: "300px"
  },
  summaryBar: {
    display: "flex",
    background: theme.palette.primary.main,
    paddingLeft: "15px",
    fontSize: "22px",
    color: "white",
    height: "56.125px",
    alignItems: "center"
  }
}));
