// Packages
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  unreadListItem: {
    borderLeft: `4px solid ${theme.palette.primary.main}`,
  },
  unreadText: {
    fontWeight: "bold",
  },
}));
