import React, {useState, useEffect, useContext} from 'react';

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import MeContext from "../../contexts/MeContext";

// Styles
import { useStyles } from "./styles";
//TODO: make the warning modal actually visible. Currently the warning console message fires, and the the logout works correctly.


export default function IdleModal({signOut, setSignedOut}) {
  const me = useContext(MeContext);
  const classes = useStyles();

  const [idleModal, setIdleModal] = useState(true);

  const timeout = process.env.REACT_APP_TIMEOUT_MINUTES ? parseInt(process.env.REACT_APP_TIMEOUT_MINUTES): 30;

  const idleTimeout = 1000 * 60 * (timeout - 5);  //25 Minutes
  const idleLogout = 1000 * 60 * timeout; //30 Minutes
  let warnTimeout; 
  let logoutTimeout;

  const events = [
    'mousemove',
    'click',
    'keypress'
  ];

  function clearTimeoutFunc(){
    if (warnTimeout) clearTimeout(warnTimeout);

    if (logoutTimeout) clearTimeout(logoutTimeout);
  }

  function setMyTimeout(){ 
    warnTimeout = setTimeout(warn, idleTimeout);
    logoutTimeout = setTimeout(logout, idleLogout);
  }

  function resetTimeout(){
    clearTimeoutFunc();
    setMyTimeout();
  }

  const warn = () => {
    setIdleModal(true);
    console.log('warnTimeout: ', warnTimeout);
    console.log('You will be logged out automatically in 5 minutes.');
  };

  const logout = () => {
    setSignedOut(true);
    signOut();
  };

  useEffect(() => {
    for (const e in events){
      window.addEventListener(events[e], resetTimeout); 
    }
    return () => {
      for(const e in events){
        window.addEventListener(events[e], resetTimeout);
      }
    };
  },[]);


    return (

      <Dialog className={classes.container} isOpen={idleModal} toggle={() => setIdleModal(false)}>
        <DialogTitle onClick={() => setIdleModal(false)}>
            Session expire warning
        </DialogTitle>
        <DialogContent>
            your session will expire in {idleLogout / 60 / 1000} minutes. Do you want to extend the session?
        </DialogContent>
        <DialogActions>
          <button className="btn btn-info"  onClick={() => logout()}>Logout</button>
          <button className="btn btn-success" onClick={() => resetTimeout()}>Extend session</button>
        
        </DialogActions>
      </Dialog>
    );

  }