// Packages
import React from "react";
import { Link } from "react-router-dom";

// MUI Components
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

// Util
import { getOrgLink } from "util/orgUtil";

// Data
import { getRoleByKey } from "enums/ROLES";

// Styles
import { useStyles } from "./styles";

export default function OrgsTable(props) {
  const { orgs = [], me } = props;
  const classes = useStyles();

  function getTableRow(org) {
    if (!org) return null;

    let role = null;

    if (org.users && me && me.userId) {
      const orgMe = org.users.find((aUser) => aUser.userId === me.userId);

      //there is no org user then check for super access
      if (orgMe && orgMe.roleKey) {
        role = getRoleByKey(orgMe.roleKey);
      }
    }

    if (!role) {
      //still cant find role, check app wide
      if (me && me.apps) {
        const app = me.apps.find((app) => app.appName === "mss");
        if (app && app.roleKey) {
          role = getRoleByKey(app.roleKey);
        }
      }
    }

    return (
      <React.Fragment key={`${org.orgId}-section`}>
        <TableRow key={org.orgId} hover={true}>
          <TableCell component={Link} to={getOrgLink(org.orgId)} className={classes.removeUnderline}>
            <Typography className={classes.link}>{org.name}</Typography>
          </TableCell>
          <TableCell>
            <Typography>{org.users ? org.users.length : 0}</Typography>
          </TableCell>
          <TableCell>
            <Typography>{role ? role.title : "N/A"}</Typography>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  function getRoleColumnName() {
    if (me && me.firstName) {
      return `${me.firstName}'s Role`;
    }
    return "Role";
  }

  return (
    <React.Fragment>
      {orgs && (
        <Grid item xs={12}>
          <Paper className={classes.root}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography>Organization Name</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>Users</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{getRoleColumnName()}</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orgs.map((org) => {
                  return getTableRow(org);
                })}
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      )}
    </React.Fragment>
  );
}
