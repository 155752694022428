// Packages
import React from "react";

// MUI Components
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

// Styles
import { useStyles } from "./styles";

export default function TermsContent(props) {
  const classes = useStyles();
  return (
    <React.Fragment>
      <DialogTitle id="alert-dialog-title">
        <Typography variant="h5">Terms of Use</Typography>
        <Typography>Please review and agree to our terms and conditions to continue using AAISdirect.</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Typography variant="h4">Copyright Notice</Typography>
          <br />
          <Typography>
            All Contents © American Association of Insurance Services, Inc.{" " + new Date().getFullYear()}. All Rights
            Reserved.
            <br />
            <br />
            The contents of all material available on this Website are copyrighted by American Association of Insurance
            Services, Inc.
            (AAIS) unless otherwise indicated. All rights are reserved by AAIS and content may not be reproduced,
            downloaded, disseminated,
            published, or transferred in any form or by any means, except with the prior written permission of AAIS.
            Notwithstanding, AAIS Members and current Registered Users of AAIS may download pages and content consistent
            with the
            AAIS Terms of Use or Services Agreement and for use of AAIS products and services intended as advisory
            organization materials.
            However, such content may not be otherwise reproduced, downloaded, disseminated, published, or transferred
            in any form or by any means,
            except with the prior written permission of AAIS.
          </Typography>
          <br />
          <Typography variant="h4">Privacy Policy</Typography>
          <br />
          <Typography variant="h5">Introduction</Typography>
          <br />
          <Typography>
            AAIS respects your privacy and we are committed to protecting it through our compliance with this Privacy
            Policy.
            This Policy describes the types of information we may collect from you or that you may provide when you
            visit AAISdirect.com (our
            &quot;Website&quot;)
            and our practices for collecting, using, maintaining, protecting, and disclosing that information.
            <br />
            <br />
            <Typography variant="h5">What information we hold about you</Typography>
            <br />
            The type of data that we collect and process includes:
            <br />
            <br />
          </Typography>
          <Typography>• Your name or username.</Typography>
          <Typography>• Your email address.</Typography>
          <Typography>• Your IP address.</Typography>
          <Typography>
            <br />
            Further data may be collected if you choose to share it, such as if you fill out fields on your profile.
            <br />
            <br />

            We collect some or all of this information in the following cases:
            <br />
            <br />

          </Typography>
          <Typography>• You register as a member on this site.</Typography>
          <Typography>• You fill out our contact form.</Typography>
          <Typography>• You browse this site. See &quot;Cookie policy&quot; below.
          </Typography>
          <br />
          <Typography variant="h5">How your personal information is used</Typography>
          <br />

          We may use your personal information in the following ways:
          <br />
          <br />


          <Typography>
            • For the purposes of making you a registered member of our site, in order for you to contribute content to
            this site.
          </Typography>
          <Typography>
            • We may use your email address to inform you of activity on our site.
          </Typography>
          <Typography>
            • Your IP address is recorded when you perform certain actions on our site. Your IP address is never
            publicly visible.
          </Typography>
          <br />

          <Typography variant="h5">Other ways we may use your personal information </Typography>
          <br />
          <Typography>
            In addition to notifying you of activity on our site which may be relevant to you,
            from time to time we may wish to communicate with all members any important information such as
            newsletters or announcements by email. You can opt-in to or opt-out of such emails in your profile.
            <br />
            <br />
            We may collect non-personally identifiable information about you in the course of your interaction with our
            site.
            This information may include technical information about the browser or type of device you are using.
            This information will be used purely for the purposes of analytics and tracking the number of visitors to
            our site.
          </Typography>
          <br />
          <Typography variant="h5">Keeping your data secure</Typography>
          <br />
          <Typography>
            We are committed to ensuring that any information you provide to us is secure.
            In order to prevent unauthorized access or disclosure, we have put in place suitable measures and
            procedures to safeguard and secure the information that we collect.
          </Typography>
          <br />


          <Typography variant="h5">Cookie policy</Typography>
          <br />
          <Typography>
            Cookies are small text files which are set by us on your computer which allow us to provide certain
            functionality on our site,
            such as being able to log in, or remembering certain preferences.
          </Typography>
          <br />

          <Typography>This site uses cookies for numerous things, including:</Typography>
          <br />
          <Typography>
            • Registration and maintaining your preferences. This includes ensuring that you can stay logged in and
            keeping the
            site in the language or appearance that you requested.
          </Typography>
          <Typography>
            • Analytics. This allows us to determine how people are using the site and improve it.
          </Typography>
          <Typography>
            • Advertising cookies (possibly third-party). If this site displays advertising, cookies may be set by the
            advertisers to determine who has viewed an ad or similar things.
            These cookies may be set by third parties, in which case this site has no ability to read or write these
            cookies.
          </Typography>
          <Typography>
            • Other third-party cookies for things like Facebook or Twitter sharing.
            These cookies will generally be set by the third-party independently, so this site will have no ability to
            access them.
          </Typography>
          <br />
          <Typography variant="h5">Rights</Typography>
          <br />
          <Typography>
            You have a right to access the personal data we hold about you or obtain a copy of it. To do so please
            contact us. If you believe that the information we hold for you is incomplete or inaccurate, you may contact
            us to ask us to complete or correct that information.
            <br />
            <br />
            You also have the right to request the erasure of your personal data. Please contact us if you would like us
            to remove your personal data.
          </Typography>
          <br />
          <Typography variant="h5">Acceptance of this policy</Typography>
          <br />
          <Typography>Continued use of our site signifies your acceptance of this policy. If you do not accept the
            policy, then please do not use this site. When registering we will further request your explicit acceptance
            of the privacy policy.
          </Typography>
          <br />

          <Typography variant="h5">Changes to this policy</Typography>
          <br />
          We may make changes to this policy at any time. You may be asked to review and re-accept the information in
          this policy if it changes in the future.
          <br />
          <br />
          <Typography variant="h4">Disclaimer</Typography>
          <br />
          <Typography>
            The American Association of Insurance Services, Inc., (AAIS) reserves the right to add, modify and/or remove
            content of AAISdirect at any time.
            <br/>
            <br/>
            AAISdirect provides links to other websites, but AAIS does not control those sites and is not responsible
            for content, maintenance, or effects on users who visit those sites.
            <br/>
            <br/>
            The compliance resources included on this Website have been prepared by AAIS as a resource, for
            informational purposes only. Reasonable attempts have been made to ensure that the content is accurate and
            current as of the publication date. AAIS compliance resources are not intended as, and should not be
            construed to constitute legal advice and should not be relied upon as such, nor should it be relied upon as
            a substitute for independent research, review, or analysis. AAIS makes no representations, warranties, or
            guaranties, express or implied, with respect to the completeness or accuracy of the content provided or
            represented, or its fitness for any particular purpose.
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Link
          className={classes.disagree}
          href="https://www.aaisonline.com"
          color="secondary"
        >
          DISAGREE
        </Link>
        <Button
          variant="contained"
          onClick={props.handleClose}
          color="primary"
          data-test="Agree"
        >
          Agree
        </Button>
      </DialogActions>
    </React.Fragment>
  );
}
