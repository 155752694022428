// Packages
import React, { useState } from "react";

// MUI Components
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

// MUI Icons
import ContactMailIcon from "@material-ui/icons/ContactMail";
import PeopleIcon from "@material-ui/icons/People";
import PlaceIcon from "@material-ui/icons/Place";
import HistoryIcon from "@material-ui/icons/History";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

// Styles
import { useStyles } from "./styles";
import IconButton from "@material-ui/core/IconButton";
import { Link as RouterLink } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";

export default function OrgTabsSection(props) {
  const { contactsTab, usersTab, addressesTab, historyTab } = props;
  const classes = useStyles();

  const [selectedTab, setTab] = useState(0);

  function handleChange(event, newSelectedTab) {
    setTab(newSelectedTab);
  }

  return (
    <React.Fragment>
      <Paper className={classes.root} elevation={0}>
        <Tooltip
          title="Go to Organizations"
          classes={{ tooltip: classes.tooltip }}>
          <IconButton
            component={RouterLink}
            to="/orgs"
            className={classes.back}>
            <ArrowBackIcon/>
          </IconButton>
        </Tooltip>

        <Tabs
          value={selectedTab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered={true}
        >
          <Tab label="Contacts" icon={<ContactMailIcon />} />
          <Tab label="Users" icon={<PeopleIcon />} />
          <Tab label="Addresses" icon={<PlaceIcon />} />
          <Tab label="History" icon={<HistoryIcon />} />
        </Tabs>
      </Paper>
      {selectedTab === 0 && <React.Fragment>{contactsTab}</React.Fragment>}
      {selectedTab === 1 && <React.Fragment>{usersTab}</React.Fragment>}
      {selectedTab === 2 && <React.Fragment>{addressesTab}</React.Fragment>}
      {selectedTab === 3 && <React.Fragment>{historyTab}</React.Fragment>}
    </React.Fragment>
  );
}
