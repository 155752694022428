/**
 * @description This is the Risk awareness Screen
 * @author Findlay Clarke <findlayc@aaisonline.com>
 * @since 1.0.0
 * @module screens/RiskAwarenessScreen
 */

// Packages
import React from "react";

// MUI Components
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

// Components
import SummaryBar from "components/SummaryBar";
import GenericRiskCard from "components/GenericRiskCard";

// Styles
import { useStyles } from "./styles";

export default function RiskAwarenessScreen(props) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <SummaryBar hideExpand={true} title="Risk Awareness"></SummaryBar>
      <Container className={classes.container} maxWidth="xl">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
            <GenericRiskCard
              title="AAIS Wildfire Live Tracker"
              src="https://aais.maps.arcgis.com/apps/webappviewer/index.html?id=3f240fd5536f4e94b4e77ed92d4d7d40"
            ></GenericRiskCard>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
            <GenericRiskCard
              title="AAIS Wildfire Knowledge Hub"
              src="https://storymaps.arcgis.com/stories/5d421a4a474a4e0c9a10330cc65836d7"
            ></GenericRiskCard>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}
