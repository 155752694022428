// Packages
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    flexGrow: 1,
    position: "relative",
  },
  paper: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  avatar: {
    width: 40,
    height: 40,
  },
  title: {
    fontSize: theme.typography.pxToRem(16),
    textDecoration: "none",
    lineHeight: 1,
  },
  email: {
    color: "gray", //theme.palette.grey[100],
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1,
  },
  inputRoot: {
    flexWrap: "wrap",
  },
  inputInput: {
    width: "auto",
    flexGrow: 1,
  },
  divider: {
    height: theme.spacing(2),
  },
}));
