/**
 * @description This is the Access Denied Screen module that is used
 * to serve the 403 View
 * @author Findlay Clarke <findlayc@aaisonline.com>
 * @since 1.0.0
 * @module screens/AccessDeniedScreen
 */
import React from "react";

export default class AccessDeniedScreen extends React.Component {
  render() {
    window.gtag("event", "page_view", {
      page_title: "403 Screen",
      page_path: `${window.location.pathname}${window.location.hash}`,
      path_location: window.location.href,
      send_to: `${process.env.REACT_APP_GA}`
    });
    return <h1>Error code 403: Page is forbidden</h1>;
  }
}
