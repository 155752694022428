// Packages
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    position: "absolute",
    paddingTop: 10,
    right: "25px",
    fontSize: "20px",
    cursor: "pointer",
  },
}));
