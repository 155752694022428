// Packages
import React, { useState, useContext, useEffect } from "react";

// MUI
import { Grid, CardHeader } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";

// Components
import Filters from "components/Filters";

// Context
import MeContext from "contexts/MeContext";

// Data
import LINES from "enums/LINES";
import PACKAGE_TYPES from "enums/PACKAGE_TYPES";
import Hubspot from "config/hubspot";
import * as OrgApi from "apis/OrgApi";

/**
 * @description generates an array containing years from 2017 to current year in string format
 * @since 1.0.0
 * @example
 * yearsGenerator()
 * //this returns ['2017', '2018', '2019', '2020', etc.]
 */
export function yearsGenerator() {
  let initYear = 2017;
  let years = [];
  const currentYear = new Date().getFullYear();

  while (initYear <= currentYear) {
    years = [...years, `${initYear}`];
    initYear++;
  }

  return years;
}

export default function ProductRequestModal(props) {
  const me = useContext(MeContext);
  const { firstName, lastName, email, phoneNumbers } = me;
  const [searchedTerms, setSearchedTerms] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [isRequestSubmitted, setIsRequestSubmitted] = useState(false);
  const [isRequestValid, setIsRequestValid] = useState(false);
  const [dateFieldError, setDateFieldError] = useState(false);
  const [dateHelperText, setDateHelperText] = useState("");
  const [effectiveDate, setEffectiveDate] = useState(new Date());
  const [effectiveOldestDate, setEffectiveOldestDate] = useState(new Date());
  const [myOrg, setMyOrg] = useState("");

  const isSubmitDisabled = !additionalInfo; //!searchedTerms || 


  useEffect(() => {
    async function fetchOrg() {
      if(me.orgs && me.orgs[0] && me.orgs[0] != null){
        const thisOrg =  await OrgApi.get(me.orgs[0]);
        setMyOrg(thisOrg.name);
      }
    }
    fetchOrg();
  }, []);

  
  const getPhomeNumbers = () => {
    if (!phoneNumbers) return "";

    let contactNumbers = [];

    for (const location in phoneNumbers) {
      contactNumbers = phoneNumbers[location].map((number) => {
        return ` ${number.countryCode}  ${number.phoneNumber} ${
          number.extension ? `, ext: ${number.extension} ` : " "
        }`;
      });
    }

    return contactNumbers.join(";");
  };

  const getMainPhoneNumber = () => {
    if (!phoneNumbers) return "";

    let contactNumbers = [];

    if('office' in phoneNumbers && phoneNumbers['office'][0]){
      const number = phoneNumbers['office'][0];
      return ` ${number.countryCode}  ${number.phoneNumber} ${
        number.extension ? `, ext: ${number.extension} ` : " "
      }`;
    }

    for (const location in phoneNumbers) {
      contactNumbers = phoneNumbers[location].map((number) => {
        return ` ${number.countryCode}  ${number.phoneNumber} ${
          number.extension ? `, ext: ${number.extension} ` : " "
        }`;
      });
    }

    return contactNumbers[0];
  };

  const handleSubmit = async () => {
    const user = `${firstName} ${lastName}`;
    let productLines = props.line.map((line) => {
      return LINES[line].title;
    });
    if( productLines.length === 0 && props.initalQuery){
      productLines = ['ALLPRODUCTS'];
    }

    let statesArray = props.states;
    if( statesArray.length === 0 && props.initalQuery){
      statesArray = ['ALLSTATES'];
    }

    const effectiveDate = props.effectiveDate;
    const effectiveOldestDate = props.effectiveOldestDate;


    const sender = new Hubspot();
    const fixedSearchedTerms = props.searchTerm.length <= 2 ? '   ' : props.searchTerm;//searchTerm

    const number = getMainPhoneNumber();
    const submission = {
      email: me.email,
      firstName: user,
      phone: number,
      subject: `${productLines} ${user}'s ${new Date().toLocaleDateString(
        "en-GB",
        {
          timeZone: "UTC",
        }
      )}`,
      content: additionalInfo,
      effectiveDate: effectiveDate,
      oldestDate: effectiveOldestDate,
      searchedTerms: fixedSearchedTerms,
      excludeFileContent: props.excludeFileContent,
      products: productLines,
      productStates: statesArray,
      materialTypes: props.packageTypeCategory,
      docTypes: props.packageType,
      imgClass: props.imgClass_s,
      plans: props.plans,
      org: myOrg,
    };
    sender.prepareData(submission);
    const response = await sender.sendProductHelpFormData();

    if (response.status === "error") {
      setIsRequestValid(false);
    } else {
      setIsRequestValid(true);
    }

    setIsRequestSubmitted(true);
  };

  const handleKeepBrowsing = () => {
    props.handleRequestModal();
  };

  const handleDateChange = (newDate) => {
    const stringDate = JSON.stringify(newDate);
    const selectedDateYear = stringDate.substring(1, 5);
    const years = yearsGenerator();
    const yearisValid = years.includes(selectedDateYear);

    if (newDate == null) {
      triggerDateError("Please provide a valid date", true);
      return;
    }

    if (!yearisValid) {
      const today = new Date(Date.now()).toLocaleString().split(",")[0];
      triggerDateError(
        `Please provide a date between 01/01/2017 and ${today}`,
        true
      );
      return;
    }

    setEffectiveDate(newDate);
    triggerDateError("", false);
  };

  const triggerDateError = (message, status) => {
    setDateFieldError(status);
    setDateHelperText(message);
  };

  return (
    <React.Fragment>
      {isRequestSubmitted ? (
        isRequestValid ? (
          <>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Your request was submitted. One of our product managers will
                contact you soon.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={handleKeepBrowsing}
                color="primary"
                data-test="keep-browsing-products"
              >
                Keep Browsing Products
              </Button>
            </DialogActions>
          </>
        ) : (
          <>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                There was a problem while submitting your data, please try again
                later or contact an administrator
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={handleKeepBrowsing}
                color="primary"
                data-test="keep-browsing-products"
              >
                Keep Browsing Products
              </Button>
            </DialogActions>
          </>
        )
      ) : (
        <>
          <CardHeader
            title="How can we help you find the information you are looking for?"
            action={
              <IconButton
                aria-label="clear"
                data-test="clear"
                color="primary"
                onClick={props.handleRequestModal}
              >
                <ClearIcon />
              </IconButton>
            }
          />
          <DialogContent>
              {/* What Can We Do For You? Section */}
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={4}
                  autoCorrect="off"
                  spellCheck="false"
                  placeholder="What can we do for you?"
                  data-test="request-modal-additional-info"
                  value={additionalInfo}
                  onChange={(event) => setAdditionalInfo(event.target.value)}
                ></TextField>
              </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={handleSubmit}
              color="primary"
              data-test="submit-modal-request"
              disabled={isSubmitDisabled}
            >
              Submit
            </Button>
          </DialogActions>
        </>
      )}
    </React.Fragment>
  );
}
