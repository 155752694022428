// Packages
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// MUI Components
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import LinearProgress from "@material-ui/core/LinearProgress";

// Services
import * as AssetApi from "apis/AssetApi";

export default function DownloadMaterialsDialog(props) {
  const [loadingMessage, setLoadingMessage] = useState(
    "Please wait while we process your request..."
  );
  const [continueProcessing, setContinueProcessing] = useState(true);
  const [downloadUrl, setDownloadUrl] = useState(null);
  const navigate =  useNavigate();

  useEffect(() => {
    asycDownloadWrapper();
  }, []);

  //analytics
  useEffect(() => {
    if (props.lineKey && props.states && props.packageTypes) {
      window.gtag("event", "package.download.states", {
        event_category: props.lineKey,
        event_label: JSON.stringify(props.states),
        value: props.states.length,
      });
      window.gtag("event", "package.download.packageTypes", {
        event_category: props.lineKey,
        event_label: JSON.stringify(props.packageTypes),
        value: props.packageTypes.length,
      });
    }
  }, []);

  async function asycDownloadWrapper() {
    let manifestId, zipFileName;
    if (continueProcessing) {
      manifestId = props.manifestId ? props.manifestId : await createManifest();
    }

    if (continueProcessing && manifestId) {
      zipFileName = await createZipFile(manifestId);
    }
    if(zipFileName == null){
      if(props.lineKey && props.lineKey[0]){
        const line = props.lineKey;
        const url = `/lines/${line[0]}`;
        setDownloadUrl(url);
      }
      else{
        const url = `/lines/All`;
        setDownloadUrl(url);
      }
      

      setLoadingMessage(`Your content is being downloaded. If the download exceeds 30 seconds, the zip file will be available on the download page in no more than 15 minutes. Thank you.`);
      setContinueProcessing(false);
    }


    if (continueProcessing && zipFileName) {
      downloadFile(zipFileName);
    }
  }

  function handleCancel() {
    setContinueProcessing(false);
    props.handleClose();
  }

  function redirectToDownloads(){
    console.log('redirectToDownloads downloadUrl: ', downloadUrl);
    console.log(props);
    console.log(window.location.href);
    console.log(window.location.href.substring(window.location.href.lastIndexOf('#') + 1));
    if(downloadUrl != null && downloadUrl !== window.location.href.substring(window.location.href.lastIndexOf('#/') + 1)){
      navigate(downloadUrl);
    }
    else{
      console.log('already on lines page');
      window.location.reload();
    }
  }

  async function createManifest() {
    /*if (
      !Array.isArray(props.lineKey) ||
      props.lineKey.length <= 0 ||
      !Array.isArray(props.states) ||
      props.states.length <= 0
    ) {
      console.error(
        `lineKey [${props.lineKey}] states [${props.states}] required params not set correctly`
      );
      setLoadingMessage("Products and states are required to download");
      setContinueProcessing(false);
      return null;
    }*/

    const manifest = await AssetApi.createManifestV2(
      props.lineKey,
      [],
      props.states,
      true,
      undefined,
      props.packageTypes,
    );
    console.log(manifest);
    if (manifest.files && manifest.files.length === 0) {
      setLoadingMessage("There are no files to be delivered");
      setContinueProcessing(false);
      return;
    }
    return manifest.id;
  }

  async function createZipFile(manifestId) {
    try{
      const zipFileName = await AssetApi.createZipFileV1(manifestId).catch((error) => {
        return null;
      });
      return zipFileName;
    }catch(error){
      return null;
    }
  }

  function downloadFile(zipFileName) {
    const prefixUrl = process.env.REACT_APP_MSS_PUBLIC_TEMP_CACHE_URL;
    const url = `${prefixUrl}/${zipFileName}`;
    window.open(url, "_parent");
    props.handleClose();
  }

  return (
    <Dialog
      open={true}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth="md"
      disableEscapeKeyDown

    >
      <DialogTitle id="alert-dialog-title">Downloading Content</DialogTitle>
      <DialogContent>
        {continueProcessing && <LinearProgress />}
        <DialogContentText id="alert-dialog-description">
          {loadingMessage}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Dismiss
        </Button>
        {downloadUrl != null && 
          <Button onClick={redirectToDownloads} color="primary">
            Go To Download Page
          </Button>
        }

      </DialogActions>
    </Dialog>
  );
}
