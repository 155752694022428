// Packages
import React, { useState } from "react";

// MUI Components
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import { useNavigate } from "react-router-dom";

// Services
import * as OrgApi from "apis/OrgApi";

export default function OrgDialog(props) {
  const [name, setName] = useState(props.name);
  const [companyNumber, setCompanyNumber] = useState(props.companyNumber);
  const navigate = useNavigate();

  const handleClick = async() => {
    await props.handleCreate(name, companyNumber);

    const tempOrgs = await OrgApi.getAll();

    console.log(tempOrgs);
    console.log(companyNumber);
    console.log(name);
    let requiredOrg = null;

    tempOrgs.forEach((org) => {
      console.log(org.name);
      console.log(org.companyNumber);
      if (companyNumber === org.companyNumber && name === org.name) {
       requiredOrg = org;
      }
    });

    navigate(`/orgs/${requiredOrg.orgId}`);
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth={true}
      open={true}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Organization Information</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Name"
          value={name}
          type="text"
          fullWidth
          onChange={(event) => setName(event.target.value)}
        />
      </DialogContent>
      <DialogContent>
        <TextField
          margin="dense"
          id="companyNumber"
          label="Company number"
          value={companyNumber}
          type="number"
          fullWidth
          onChange={(event) => setCompanyNumber(event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          Cancel
        </Button>
        <Button
          variant="contained"
          // component={RouterLink}
          // to='/orgs/orgId'
          onClick={() => handleClick()}
          color="primary"
          disabled={
            !name ||
            name.length <= 4 ||
            !companyNumber ||
            companyNumber.length <= 3
          }
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}
