// Packages
import React, { useContext, useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";

// MUI Components
import Grid from "@material-ui/core/Grid";
import { LinearProgress, Container } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

// Components
import NotificationsList from "components/NotificationsList";
import AAISPDFViewerDialog from "components/AAISPDFViewerDialog";
import { fileNamesConvert } from "util/commonUtil";


// Styles
import { useStyles } from "./styles";

// Services
import * as UserApi from "apis/UserApi";

// Context
import MeContext from "contexts/MeContext";

export default function NotificationScreen(props) {
  const classes = useStyles();
  const me = useContext(MeContext);
  const [notifications, setNotifications] = useState([]);
  const [selectedNotificationIds, setSelectedNotificationIds] = useState([]);
  const [loading, setLoading] = useState([]);
  const [showOnlyUnread, setShowOnlyUnread] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [pdfViewerDialogOpen, setPdfViewerDialogOpen] = useState(false);
  const [dialogNotification, setDialogNotification] = useState();

  useEffect(() => {
    window.gtag("event", "page_view", {
      page_title: "Notifications Screen",
      page_path: `${window.location.pathname}${window.location.hash}`,
      path_location: window.location.href,
      send_to: `${process.env.REACT_APP_GA}`
    });
  }, []);

  useEffect(() => {
    getNotifications();
  }, [me, showOnlyUnread]);

  async function getNotifications() {
    if (!me) return;

    setLoading(true);
    const tempNotifications = await UserApi.getNotifications(!showOnlyUnread);
    setNotifications(tempNotifications);
    setLoading(false);
  }

  function handleChecked(notificationId, checked) {
    if (checked) {
      setSelectedNotificationIds(
        selectedNotificationIds.concat([notificationId])
      );
    } else {
      setSelectedNotificationIds(
        selectedNotificationIds.filter((sId) => sId !== notificationId)
      );
    }
  }

  function handleToggleSelectAll() {
    if (selectedNotificationIds.length !== notifications.length) {
      const temp = notifications.map((n) => n.notificationId);
      setSelectedNotificationIds(temp);
    } else {
      setSelectedNotificationIds([]);
    }
    handleClose();
  }

  async function handleMarkAsRead() {
    setLoading(true);
    await Promise.all(
      selectedNotificationIds.map((nId) => {
        return UserApi.markNotificationAsRead(nId, true);
      })
    );
    setLoading(false);
    handleClose();
    window.location.reload();
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleNotificationClick(notification) {
    setDialogNotification(notification);
    setPdfViewerDialogOpen(true);
    UserApi.markNotificationAsRead(notification.notificationId, true);
  }

  return (
    <React.Fragment>
      {loading && <LinearProgress></LinearProgress>}
      <Container maxWidth={false} className={classes.container}>
        <Paper className={classes.paper}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <ToggleButtonGroup
                value={showOnlyUnread}
                size="small"
                exclusive
                onChange={(e, value) => {
                  value !== null && setShowOnlyUnread(value);
                }}
              >
                <ToggleButton value={false}>All</ToggleButton>
                <ToggleButton value={true}>Unread</ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid item>
              <Button variant="outlined" color="primary" onClick={handleClick}>
                Actions
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleToggleSelectAll}>
                  Toggle Select All
                </MenuItem>
                <MenuItem
                  onClick={handleMarkAsRead}
                  disabled={selectedNotificationIds.length === 0}
                  divider
                >
                  Mark as Read
                </MenuItem>
                <MenuItem
                  component={RouterLink}
                  to={`/users/${me.userId}/bulletins`}
                >
                  Notification Preferences
                </MenuItem>
              </Menu>
            </Grid>
          </Grid>
        </Paper>
      </Container>
      <Container maxWidth={false} className={classes.container}>
        <NotificationsList
          notifications={notifications}
          selectedNotificationIds={selectedNotificationIds}
          handleChecked={handleChecked}
          reload={getNotifications}
          handleNotificationClick={handleNotificationClick}
        ></NotificationsList>
      </Container>
      {pdfViewerDialogOpen && (
        <AAISPDFViewerDialog
          s3Key={fileNamesConvert(dialogNotification.files)}
          title={dialogNotification.title}
          handleClose={() => setPdfViewerDialogOpen(false)}
        ></AAISPDFViewerDialog>
      )}
    </React.Fragment>
  );
}
