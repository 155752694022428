// Packages
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  resultsHeader: { marginTop: "15px", marginLeft: "20px", marginBottom: -20},
  resultsHeaderMin : { marginTop: 35, marginLeft: "20px", marginBottom: -20},
  grow: {
    flexGrow: 1,
  },
  hits: {
    fontSize: 14,
    width: "fit-content",
    color: "#979797",
    flexGrow: 1,
    paddingLeft: theme.spacing(1),
    marginBottom: -20,
  },
  item: {
    width: "100%",
    marginTop: 1,
    marginBottom: 0,
    flexGrow: 1,
  },
  listItem: {
    width: "100%",
    //minWidth: "700px",
    minHeight: "45px",
    marginTop: 1,
    paddingLeft: 1,
    paddingRight: 1,
    marginBottom: 1,
    flexGrow: 0,
    // display: "flex",
    flexDirection: "row",
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    borderLeft: `1px solid ${theme.palette.grey[300]}`,
    borderRight: `1px solid ${theme.palette.grey[300]}`,
    // borderBottom: `1px solid ${theme.palette.grey[300]}`,
    //width: "900px",
    //backgroundColor: "orange",
  },
  resultsContainer: {
    marginTop: 5,
    marginBottom: 50,
    paddingLeft: 0,
    paddingRight: 0,
    //minWidth: "700px",
    flexDirection: "row",
    width: "100%",
    display: "table",
    //borderTop: `1px solid ${theme.palette.grey[300]}`,
    //borderLeft: `1px solid ${theme.palette.grey[300]}`,
    //borderRight: `1px solid ${theme.palette.grey[300]}`,
    //borderBottom: `1px solid ${theme.palette.grey[300]}`,
    //gridTemplateColumns: "repeat(auto-fill, 200px);",
    //justifyContent: "space-between",
  },

  "@media (max-width: 100%)": {
    item: {
      maxWidth: "100%",
      minWidth: "100%",
      marginTop: 7,
      marginLeft: 2,
      marginRight: 2,
      marginBottom: 0,
      flexGrow: 1,
    },
    resultsContainer: {
      gridTemplateColumns: "repeat(1, 1fr)",
    },
  },

  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(32),
  },
  title: {
    cursor: "pointer",
    paddingBottom: 2, 
  },
  titleNoLink: {
    paddingBottom: 2,
    color: "#000000",
  },
  card: {
    flexGrow: 1,
    orientation: "horizontal",
  },
  cardHeader: {
    minHeight: 100,
  },
  cardContent: {
    paddingTop: 0,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  chipsPaper: {
    display: "flex",
    justifyContent: "left",
    flexWrap: "wrap",
    width: "100%",
    paddingLeft: "5px",
    paddingRight: "5px",
    paddingBottom: "5px"
  },
  chip: {
    margin: theme.spacing(0.25),
    minWidth: "45px",
  },
  chipSelected: {
    backgroundColor: "#5c666f",
    color: "white",
    margin: "2px",
    padding: "4px",
    minWidth: "45px"
    //filter: "grayscale(80%)",
  },
  chipProductSelected: {
    backgroundColor: "#5c666f",
    color: "white",
    margin: "2px",
    padding: "4px",
    minWidth: "45px"
    //backgroundColor: "#204286"
  },
  chipNotFound: {
    color: "#949494",
    margin: "2px",
    padding: "4px",
    minWidth: "45px"
  },
  cardActions: {
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    borderLeft: `1px solid ${theme.palette.grey[300]}`,
  },
  highlight: {
    fontSize: 13,
    color: theme.palette.grey[600],
    height: 100,
    flexGrow: 1,
    overflowY: "hidden",
    //border: "1px solid green",
  },
  currentIcon: {
    color: "#4caf50",
  },
  notCurrentIcon: {
    color: "#dc004e",
  },
  moreInfoButton: {
    //display: "inline-flex",
    //position: "relative",
    marginLeft: "7px",
    flexShrink: 0,
    alignItems: "start",
    justifyContent: "inherit",
    boxSizing: "border-box",
    //minWidth: "17px"
    fontSize: "16px",
    '&:hover': {
      textDecoration: "none",
      color: "#0097AC"
    }
  },
  infoIcon: {
    marginLeft: "7px",
    color: "#1f4287",
    fontSize: "16px",
    '&:hover': {
      textDecoration: "none",
      color: "#0097AC"
    }
  },
  expandButton: {
    marginLeft: "auto !important",
  },
  divider: {
    marginTop: 5,
  },
  infiniteScroll: {
    overflow: "visible !important",
  },
  rightContent: {
    position: "absolute",
    right: "25px",
    bottom: "17px",
    fontSize: "22px",
    cursor: "pointer",
    '&:hover': {
      textDecoration: "none",
      color: "#0097AC"
    }
  },
  rightContentDownload: {
    bottom: "10px",
    position: "relative",
    left: "0px",
    marginRight: "5px",
    color: "#1f4287",
    fontSize: "22px",
    cursor: "pointer",
    '&:hover': {
      textDecoration: "none",
      color: "#0097AC"
    }
  },
  dialogTitle: {
    position: "relative",
  },
  csvLink: {
    position: "absolute",
    bottom: "0px",
    right: "50px",
  },
  materialType: {
    fontSize: "0.8571rem",
    textAlign: "right",
    fontStyle: "italic",
    color: "black",
    right: 0,
    position: "relative",
    marginRight: "2px"
  },
  openInNewIcon: {
    color: "#1f4287",
    cursor: "pointer",
    transform: "translateY(21%)",
    fontSize: "16px",
    marginLeft: "5px",
    '&:hover': {
      textDecoration: "none",
      color: "#0097AC"
    }
  },
  openInNewIconInner: {
    position: "absolute",
    bottom: "20px",
    color: "#1f4287",
    cursor: "pointer",
    fontSize: "19px",
    marginLeft: "7px",
    paddingTop: "1px",
    '&:hover': {
      textDecoration: "none",
      color: "#0097AC"
    }
  },
  newIconSpan: {
    padding: "10px"
  },
  category: {
    fontStyle: "italic",
    color: "black",
    paddingTop: "8px",
    paddingRight: "24px",
    paddingBottom: "8px",
    paddingLeft: "24px",
  },
  tooltip: {
  },
  downloadButton: {
    backgroundColor: "#1f4287",
    color: "white",
    "&:disabled": {
      pointerEvents: "auto",
      color: "white",
    },
    width: "130px",
  },
  sortButton: {
    backgroundColor: "#1f4287",
    left: 0,
    color: "white",
    "&:disabled": {
      pointerEvents: "auto",
      color: "white",
    },
    width: "130px",
  },
  sortArrow: {
    transform: "translateY(25%)",
    backgroundColor: "#1f4287",
    color: "white",
    "&:disabled": {
      pointerEvents: "auto",
      color: "white",
    },
  },
  requestHelpButton: {
    backgroundColor: "#1f4287",
    color: "white",
    marginLeft: "3px",
    width: "130px",
  },
  flexboxContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    backgroundColor: "red",
  },
  flexboxItem: {
    width: "200px",
    //flexDirection: "row",
  },
  flexboxItemSidebar:{
    //width: "20%",
    right: 0,
    marginLeft: "8px", 
    marginRight: "5px",
    //backgroundColor: "blue",
    //borderTop: `1px solid ${theme.palette.grey[300]}`,
    borderLeft: `1px solid ${theme.palette.grey[300]}`,
    alignSelf: "flex-end",
    display: "flex",
    flexDirection: "row",
  },
  flexboxItemSidebarOther:{
    //width: "20%",
    right: 0,
    marginLeft: "1px", 
    marginRight: "5px",
    alignSelf: "flex-end",
    //backgroundColor: "blue",
    //borderTop: `1px solid ${theme.palette.grey[300]}`,
    //borderLeft: `1px solid ${theme.palette.grey[300]}`,
  },
  flexboxItemMain: {
    //width: "80%",
    alignItems: "center",
    marginLeft: "10px",
    color: "#1f4287",
    fontSize: "0.8571rem",
    //"-ms-transform": "translateY(-50%)",
    transform: "translateY(-10%)",
    //color: "red"
    "&:hover": {
      textDecoration: "none",
      color: "#0097AC"
    },   
  },
  flexboxItemOther: {
    width: "0%",
  },
  flexboxMaterialType: {
    right: 0,
    marginLeft: "auto",
    //alignSelf: "flex-end",
    display: "flex",
    flex: 0,
    textAlign: "right",
    alignContent: "right",
    alignSelf: "right",
    whiteSpace: "nowrap",
  },
  flexboxRight: {
    right: 0,
    marginLeft: "auto",
    marginRight: "5px",
    //alignSelf: "flex-end",
    display: "flex",
    flexDirection: "row",
    flex: 0,
    textAlign: "right",
    alignContent: "right",
    alignSelf: "right"
  },
  moreTable: {
    marginBottom: "5px",
    border: "5px",
    paddingBottom: "5px",
  },
  browseToolBar: {
    paddingLeft: "20px",
    marginBottom: -20,
  },
  closeButton: {
    "&:hover": {
      textDecoration: "none",
      color: "#0097AC",
      backgroundColor: "none !important",
    },   
  }
}));
