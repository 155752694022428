// Packages
import React, { useContext, useEffect, useState } from "react";

// MUI Components
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { Container } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import LinearProgress from "@material-ui/core/LinearProgress";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import TextField from "@material-ui/core/TextField";

// Components
import OrgsTable from "components/OrgsTable";
import OrgDialog from "components/OrgDialog";

// Styles
import { useStyles } from "./styles";

// Context
import MeContext from "contexts/MeContext";

// Services
import * as OrgApi from "apis/OrgApi";

// Util
import * as userUtil from "util/userUtil";

export default function OrgsScreen(props) {
  const classes = useStyles();
  const me = useContext(MeContext);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [orgs, setOrgs] = useState([]);
  const [allOrgs, setAllOrgs] = useState([]);
  const [snack, setSnack] = useState({
    open: false,
    note: null,
    severity: "info",
  });

  useEffect(() => {
    window.gtag("event", "page_view", {
      page_title: "Orgs Screen",
      page_path: `${window.location.pathname}${window.location.hash}`,
      path_location: window.location.href,
      send_to: `${process.env.REACT_APP_GA}`
    });
  }, []);

  useEffect(() => {
    getOrgs();
  }, []);

  async function getOrgs() {
    setLoading(true);
    const tempOrgs = await OrgApi.getAll();
    setOrgs(tempOrgs);
    setAllOrgs(tempOrgs);
    setLoading(false);
  }

  function filterOrgs(term){
    if(term && term.length > 0){
      const newOrgs = [];
      for(const anOrg in allOrgs){
        if(allOrgs[anOrg].name.toLowerCase().includes(term.toLowerCase())){
          newOrgs.push(allOrgs[anOrg]);
        }
      }
      setOrgs(newOrgs);
    }
    else{
      setOrgs(allOrgs);
    }
  }

  function getTitle() {
    if (me?.firstName) return `${me.firstName}'s Organizations`;

    return `Your Organizations`;
  }

  async function handleCreate(name, companyNumber) {
    setLoading(true);
    setCreateDialogOpen(false);
    const resp = await OrgApi.create(name, companyNumber);
    if (resp.companyNumber) {
      setSnack({
        open: true,
        note: `Company ${name} with number ${companyNumber} created`,
        severity: "success",
      });
      await getOrgs();
    } else {
      setSnack({
        open: true,
        note: resp.response.data.friendlyErrorMessage || "Something went wrong",
        severity: "error",
      });
    }
    setLoading(false);
  }

  function getOrgsBar() {
    return (
      <Container maxWidth={false} className={classes.container}>
        <Paper className={classes.paper}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <TextField
                variant="outlined"
                fullWidth
                autoCorrect="off"
                spellCheck="false"
                label="Search"
                placeholder="Search"
                data-test="orgsFilterSearch"
                onChange={(event) => filterOrgs(event.target.value)}
                className={classes.searchText}
              />
            </Grid>
            <Grid item></Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setCreateDialogOpen(true)}
              >
                Create Organization
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    );
  }

  return (
    <React.Fragment>
      {
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={snack.open}
          onClose={() =>
            setSnack({ open: false, note: null, severity: snack.severity })
          }
          autoHideDuration={3000}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
        >
          <Alert severity={snack.severity} sx={{ width: "100%" }}>
            {<span>{snack.note}</span>}
          </Alert>
        </Snackbar>
      }
      <div className={classes.summaryBar}>
        {getTitle()}
      </div>
      {me && userUtil.isEmployee(me) && userUtil.isAdmin(me) && getOrgsBar()}
      <Grid container spacing={0} className={classes.grid}>
        {loading && (
          <Grid item xs={12}>
            <LinearProgress />
          </Grid>
        )}
        {me?.userId && <OrgsTable orgs={orgs} me={me} />}
      </Grid>
      {createDialogOpen && (
        <OrgDialog
          handleCreate={handleCreate}
          handleClose={() => setCreateDialogOpen(false)}
        ></OrgDialog>
      )}
    </React.Fragment>
  );
}
