/**
 * @description LandingPageApi Module. This will have all the API's needed
 * to interact with the backend API's for the Landing Pages
 * @module apis/AssetApi
 * @since 1.0.0
 * @author David Ganz <davidg@aaisonline.com>
 * @public
 */

import { API } from "aws-amplify";
import { sortOrgs as sortLandingPages } from "../util/orgUtil";
import * as commonUtil from "../util/commonUtil";



/**
 * @author David Ganz
 *
 * @description Allows the querying of the Landing Page/Landing Pages
 *
 * @param landingPageId pass the unique identifier for this Landing Page
 * if you are trying to get a specific Landing Page. If you would like
 * all the Landing Page just call this method with
 * the `landingPageId` set it to undefined
 * @example
 * get("landingPageId123"); //will return the specific Landing Page
 *
 * @returns a particular landingPage if a `landingPageId` is passed in
 *
 */
export async function get(landingPageId) {
  if (!landingPageId) return null;

  let landingPage;
  const path = `/landingPages/${landingPageId}`;

  try {
    landingPage = await API.get("LANDINGPAGE", path);
    //storeLandingPageInCache(landingPage);
  } catch (error) {
    console.log(error);
    return error;
  }

  return landingPage;
}



export async function getLandingPages(landingPageIds) {
  if (!Array.isArray(landingPageIds)) return [];

  //convert array to comma separated list
  const landingPageIdsString = commonUtil.filterAndJoinResults(landingPageIds);

  if (!landingPageIdsString) return [];

  const path = `/landingPages?landingPageIds=${landingPageIdsString}`;

  try {
    const landingPages = await API.get("LANDINGPAGE", path);
    //storeLandingPagesInCache(landingPages); //TODO: consider FULL implementation of caching
    return sortLandingPages(landingPages);
  } catch (error) {
    console.log(error);
    return error ;
  }
}

/* TODO: consider FULL implementation of caching
export function getLandingPagesFromCache(landingPageIds) {
  if (!landingPageIds || !Array.isArray(landingPageIds)) return;

  const landingPages = [];
  landingPageIds.forEach((landingPageId) => {
    const landingPage = getFromCache(landingPageId);
    if (landingPage) landingPages.push(landingPage);
  });

  return sortLandingPages(landingPages);
}*/

export async function getAll() {
  try {
    return await API.get("LANDINGPAGE", "/landingPages");
  } catch (error) {
    console.error("an error occurred", error);
    return error;
  }
}

/**
 * @description Creates an landingPage
 * @param {string} name The name of the landingPage you are creating
 * @returns {object} a created landingPage object
 */
export async function create(name) {
  if (!name) return null;

  const path = `/landingPages`;
  const body = { name };

  const init = {
    body,
  };
  try {
    return await API.post("LANDINGPAGE", path, init);
  } catch (error) {
    return error;
  }
}

/**
 * @description Updates an landingPage name
 * @param {string} key The name of the landingPage you are updating
 * @param {string} value The name of the landingPage you are updating
 * @returns {object} a created landingPage object
 */
export async function update(landingPageId, key, value) {
  if (!landingPageId || !key || !value) return null;

  const path = `/landingPages/${landingPageId}`;
  const body = { key: key, value: value };

  const init = {
    body: body,
  };

  try {
    return await API.put("LANDINGPAGE", path, init);
  } catch (error) {
    console.log(error);
    return error;
  }
}


/**
 * @author David Ganz <davidg@aaisonline.com>
 *
 * @description Get transactions for a specific landingPage
 *
 * @param {string} landingPageId pass the unique identifier of the landingPage
 * the `landingPageId` set it to undefined
 * @param {object} [exclusiveStartKey] used to paginate the results. use the
 * `lastEvaluatedKey` from the previous query to get the next batch of data
 * @example
 * get("landingPageId123"); //will return the specific landingPage transactions
 *
 * @returns a particular landingPage if a `landingPageId` is passed in
 *
 * @throws {NotAuthorizedException} Trying to access a partiular User
 * when the caller is not authorized
 */
export async function getTransactions(landingPageId, exclusiveStartKey = undefined) {
  if (!landingPageId) return null;
  let transactions;
  const path = `/landingPages/${landingPageId}/transactions`;
  const init = {
    body: { exclusiveStartKey },
  };

  try {
    transactions = await API.post("LANDINGPAGE", path, init);
  } catch (error) {
    console.log(error);
    return error;
  }

  return transactions;
}

/**
 * @description Gets a copy of a specific landingPage from local storage.
 * This helps with optimization so that we can fetch from cache first before going
 * to the REST API's
 * @private
 * @author David Ganz <davidg@aaislonline.com>
 * @param {*} landingPageId the unique landingPageId for the landingPage to get
 * @since 1.0.0
 */
/* TODO: consider FULL implementation of caching
export function getFromCache(landingPageId) {
  if (!landingPageId) return;

  const landingPageJSON = localStorage.getItem(landingPageId);

  try {
    return JSON.parse(landingPageJSON);
  } catch (error) {
    console.error(`could not parse landingPage object ${landingPageJSON}`, error);
  }

  return null;
}*/

/*
###############################################################################
                                PRIVATE APIS
                  DO NOT CALL THESE FROM OUTSIDE THIS MODULE
###############################################################################
*/

/**
 * @description Sets a copy of a specific landingPage to local storage.
 * This helps with optimization so that we can fetch from cache first before going
 * to the REST API's
 * @private
 * @author David Ganz <davidg@aaisonline.com>
 * @param {*} landingPageId the unique landingPageId for the landingPage to get
 * @since 1.0.0
 */

/* TODO: consider FULL implementation of caching
function storeLandingPageInCache(landingPage) {
  if (!landingPage || !landingPage.landingPageId) return;
  localStorage.setItem(landingPage.landingPageId, JSON.stringify(landingPage));
}

function storeLandingPagesInCache(landingPages) {
  if (!landingPages || !Array.isArray(landingPages)) return;

  landingPages.forEach((landingPage) => storeLandingPageInCache(landingPage));
}
*/

/*
###############################################################################
                                END PRIVATE APIS
                  DO NOT CALL THESE FROM OUTSIDE THIS MODULE
###############################################################################
*/
