// Packages
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  list: {
    flexGrow: 1,
  },
  cardHeader: {
    backgroundColor: "#5C666F",
    textAlign: "center",
    color: "white"
  },
  cardContent: {
    maxHeight: 280,
    overflowY: "auto",
    textAlign: "left"
  },
  link: {
    textDecoration: "none !important",
    cursor: "pointer",
    transition: "color 0.2s",
    "&:hover": {
        color: "#0097AC"
    }
  },
  text: {
    fontSize: "16px"
  },
  card: {
    height: "160px",
  },
}));
