// Packages
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  tooltip: {
    fontSize: theme.typography.pxToRem(15),
  },
  loadingRow: {
    padding: 0,
    height: 0,
  },
  loadingCell: {
    padding: 0,
  },
  roleKeySelector: {
    minWidth: 125,
  },
  rightContent: {
    position: "absolute",
    right: "25px",
    fontSize: "20px",
    cursor: "pointer",
  },
  urlCell: {
    color: "#1f4287",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "none",
      color: "#0097AC"
    },  
  },
  table: {
    textAlign: "left",
    position: "relative",
    borderCollapse: "collapse",
    paddingTop: "0px",
    paddingBottom: "0px",
  },
  topTable: {
    paddingTop: "0px",
    paddingBottom: "0px",
  },
  th: {
    background: "white",
    position: "sticky",
    top: -10,
    boxShadow: "0 2px 2px -1px rgba(0, 0, 0, 0.4)",
    zIndex: 10,
    paddingTop: "10px",
    borderTop: "1px",
  },
  tr: {
    zIndex: 1,
  },

}));
