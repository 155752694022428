// Packages
import React, { useContext, useEffect } from "react";

// MUI Components
import Grid from "@material-ui/core/Grid";
import { Container, Paper, Chip } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

// MUI Icons
import CertifiedIcon from "@material-ui/icons/Check";
import NotCertifiedIcon from "@material-ui/icons/NotInterested";
import CertifyingIcon from "@material-ui/icons/ReportProblemOutlined";

// Components
import SummaryBar from "components/SummaryBar";
import LineTabsSection from "components/LineTabsSection";
import DownloadMaterialsCard from "components/DownloadMaterialsCard";
import DownloadCSVMaterialsCard from "components/DownloadCSVMaterialsCard";
import DownloadHistoryCard from "components/DownloadHistoryCard";
import LineUpdates from "components/LineUpdates";

// Styles
import { useStyles } from "./styles";

// Context
import MeContext from "contexts/MeContext";

// Util
import * as UserUtil from "util/userUtil";

// Data
import LINES from "enums/LINES";
import { useParams } from "react-router-dom";

export default function LineScreen() {
  const classes = useStyles();
  const me = useContext(MeContext);
  const hidden = 1;
  let { lineKey } = useParams();
  if (!lineKey || lineKey === undefined || lineKey === "undefined"){
    lineKey = 'All';
  }

  useEffect(() => {
    window.gtag("event", "page_view", {
      page_title: "Line Screen",
      page_path: `${window.location.pathname}${window.location.hash}`,
      path_location: window.location.href,
      send_to: `${process.env.REACT_APP_GA}`
    });
  }, [lineKey]);

  function getTitle() {
    if (!lineKey || lineKey === undefined){
      lineKey = 'All';
    }
    if (!lineKey && LINES[lineKey]) return `N/A`;
    
    let status = "";
    let icon = null;
    let chipCss = null;
    if(lineKey in LINES){
      if (LINES[lineKey].certificationStatus === "CERTIFIED") {
        status = "Certified";
        icon = <CertifiedIcon></CertifiedIcon>;
        chipCss = classes.certifiedChip;
      } else if (LINES[lineKey].certificationStatus === "CERTIFYING") {
        status = "Certifying";
        icon = <CertifyingIcon></CertifyingIcon>;
        chipCss = classes.certifyingChip;
      } else if (LINES[lineKey].certificationStatus === "NOT_CERTIFIED") {
        status = "Not Certified";
        icon = <NotCertifiedIcon></NotCertifiedIcon>;
        chipCss = classes.notCertifiedChip;
      }
    }
    return (
      <React.Fragment>
        <Typography variant="h5" display="inline">
          {lineKey in LINES ? LINES[lineKey].title : 'All'} ({lineKey})
        </Typography>

        <Chip
          className={chipCss}
          label={status}
          icon={icon}
          data-test="status"
        />
      </React.Fragment>
    );
  }

  function getDescription() {
    if (!lineKey && LINES[lineKey]) return `N/A`;
    if (lineKey === 'All') return 'All';
    return `${LINES[lineKey].description}`;
  }

  function getWelcome() {
    return (
      <React.Fragment>
        <Container>
          <Paper className={classes.lineDescription}>
            <Typography data-test="lineDescription" variant="h6">
              {getDescription()}
            </Typography>
          </Paper>
        </Container>
      </React.Fragment>
    );
  }

  function getMaterialsTab() {
    return (
      <React.Fragment>
        <Container className={classes.container}>
          <Grid container spacing={3}>
           {hidden === 0 && <Grid item xs={12} md={6}>
              <DownloadMaterialsCard lineKey={[lineKey]}></DownloadMaterialsCard>
            </Grid>}
              {me?.userId && (
                <DownloadHistoryCard
                  lineKey={lineKey}
                  userId={me.userId}
                ></DownloadHistoryCard>
              )}
            {hidden === 0 && me && UserUtil.isEmployee(me) && (
              <Grid item xs={12} md={6}>
                <DownloadCSVMaterialsCard
                  lineKey={lineKey}
                ></DownloadCSVMaterialsCard>
              </Grid>
            )}
          </Grid>
        </Container>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <SummaryBar hideExpand={true} expanded={false} title={getTitle()}>
        {getWelcome()}
      </SummaryBar>
      <LineTabsSection
        materialsTab={getMaterialsTab()}
        updatesTab={<LineUpdates lineKey={lineKey}></LineUpdates>}
      />
    </React.Fragment>
  );
}
