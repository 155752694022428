// Packages
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  search: {
    marginRight: theme.spacing(2),
  },
  menuTitle: {
    marginLeft: theme.spacing(1),
  },
  appBar: {
    backgroundColor: "white",
    color: theme.palette.primary.main,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  activeButtonStyle: {
    borderBottom: "2px solid",
    borderBottomColor: theme.palette.primary.main,
    borderRadius: 0,
  },
  activeMeStyle: {
    border: "2px solid",
    borderColor: theme.palette.primary.main,
  },
}));
