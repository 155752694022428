export default function getFaqInfo() {
  const sections = [
    {
      title: "Site navigation",
      questions: [
        {
          question:
            "What if I cannot find documents?",
          answer: `After searching for content on the <b>Browse Products</b> screen, click <b>"REQUEST HELP"</b>`,
          img: "faq1.png",
          html: true,
        },
        {
          question: "How do I search for documents on AAISdirect?",
          answer: `To find documents on AAISdirect navigate to the <a href="/#/browse">Browse Products Screen</a>. There you can enter search criteria including product line, state, and effective date. You can also type specific search terms. `,
          img: "faq2.png",
          html: true,
        },
        {
          question: "How do I activate my account?",
          answer:
            "New accounts are created by AAIS engagement managers. If a new user needs an account, contact membership@aaisonline.com. When the account is created you’ll receive an activation email with your username and temporary password. Enter the credentials on the login screen and you’ll be prompted to change your password.",
        },
      ],
    },
    {
      title: "Managing my account",
      questions: [
        {
          question: "How do I change my password?",
          answer:
            "If you’ve forgotten your password and can’t log in, click the Forgot your password? link on the sign in screen. Enter your username on the Reset your password screen, and you’ll receive an email with a temporary password. Once you enter that code on the sign-in screen, you’ll be prompted to create a new password.",
          img: "faq3.png",
        },
        {
          question: "How do I update my personal information?",
          answer:
            "On the left hand navigation bar, click Settings.",
        },
        {
          question: "How do I activate my account?",
          answer:
            "When an AAIS Engagement Manager creates your account you’ll receive an activation email with your username and temporary password. Enter the username and password on the sign in screen and you’ll be prompted to create a new password. ",
        },
      ],
    },
    {
      title: "Bulletins",
      questions: [
        {
          question: "What is a bulletin?",
          html: true,
          answer: `Bulletins inform affiliates of the status of new and revised programs and may include copies of forms and manuals. Bulletins focus on critical information companies need to know and clearly identify actions companies need to take. AAISdirect provides bulletins of the following types: <br></br> 1. <strong>Bulletin </strong> - the most common bulletin format used to inform affiliates of the status of new or revised program material. Affiliates only receive bulletins for lines and states that they are subscribed to. <br></br> 2. <strong>General Bulletin </strong> - used to inform affiliates of a wide sweeping change or new release that pertains to all lines and all states. <br></br> 3. <strong>Countrywide Bulletin </strong> - used to inform affiliates of the status of new or revised program material that applies to all states. <br></br> 4. <strong>Statistical Bulletin </strong> - used to inform affiliates of new or revised statistical materials.`,
        },
        {
          question: "How do I search for a bulletin by bulletin number?",
          answer: `On the <a href="/#/browse">Browse Products Screen</a>, select the product line and state associated with the bulletin. Enter Bulletin in the material type field and then enter the bulletin number in the Search field. `,
          html: true,
        },
      ],
    },
    {
      title: "Manuals",
      questions: [
        {
          question: "What is a manual?",
          html: true,
          answer:
            "An AAIS manual publication may have several supporting publication types associated with it.  <br></br> 1. <strong>Manual. </strong> AAIS manuals provide rules for writing and rating policies, along with loss cost information. For many lines, manuals are offered on a state-by-state basis, which allows AAIS and its members to modify them quickly to reflect changes in regulation, legislation, and market conditions. <br></br> 2. <strong>State Pages. </strong>  Individual state pages may include territorial definitions, state-specific exceptions to countrywide materials, and state-specific rules and rating information. <br></br> 3. <strong>Supplement. </strong> A supplement is an additional publication added to a manual revision to complete or define changes made to the manual revision. <br></br> 4. <strong>Data Set. </strong> Data sets are provided for companies that use AAISdirect to transfer rating information, such as loss costs and factors, and territorial definitions directly into their companies' systems, eliminating the need to enter data manually which will help reduce and possibly eliminate errors. The data sets will also expedite the processes that companies use to implement program changes and will allow for easier exchange of data between multiple systems. The data sets are provided in both Microsoft Excel and XML formats.",
        },
      ],
    },
    {
      title: "Form and Endorsement Listings",
      questions: [
        {
          html: true,
          question: "What is an F & E Listing?",
          answer: `<strong>Forms and Endorsement List.</strong> The Forms and Endorsement List identifies the editions of forms and endorsements that apply in the line and state for the selected manual revision. <br></br> <strong> Tip: </strong> Use a Forms and Endorsement List to locate the approved publications for a specific manual revision within a line and state.`,
          img: "faq5.png",
        },
      ],
    },
    {
      title: "Inland Marine Publications",
      questions: [
        {
          question: "What is the Inland Marine Guide?",
          answer:
            "The <strong>Inland Marine Guide </strong> (the Guide) is AAIS's program for the traditional nonfiled commercial inland marine classes. The Guide includes forms, rating procedures, underwriting guidelines, and other information. ",
          html: true,
        },
        {
          question:
            "Which jurisdictions require the filling of forms and rules/rates for the traditional nonfiled inland marine classes?",
          answer: "See General Filing Information.",
        },
        {
          question:
            "Which inland marine classes are considered filed v. nonfiled?",
          answer:
            "To see which inland marine guide class are filed and which are non-filed, look at the Inland Marine Class List.",
        },
        {
          question:
            "Where do I find the company action for use with the Guide?",
          answer: "The company action is available in the Compliance Guide.",
        },
        {
          question:
            "Where do I find filing numbers for approved Guide filings?",
          answer:
            "Filing numbers can be found on either the state-specific Reference Filing Number documents (one for Forms and one for Rules and Rating) or the Forms and Endorsements Status Reports.",
        },
      ],
    },
  ];
  return sections;
}
