// Packages
import React from "react";

// MUI Components
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CardHeader from "@material-ui/core/CardHeader";
import Paper from "@material-ui/core/Paper";
import { Link } from "@material-ui/core";

// Styles
import { useStyles } from "./styles";

export default function ProductAdminLinksCard() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid item xs={12} className={classes.grid}>
        <Card className={classes.card}>
          <CardHeader
            className={classes.cardHeader}
            title={`Product Admin Links`}
          />
          <CardContent className={classes.cardContent}>
            <Paper className={classes.paper} elevation={0}>
              <Typography>
                <Link href={`/#/health-check`} id="link-health-check" rel="noreferrer">Health Check</Link>
              </Typography>
            </Paper>
          </CardContent>
        </Card>
      </Grid>
    </React.Fragment>
  );
}
