// Packages
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  grid: {
    marginTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
  },
  welcome: {
    paddingTop: 20,
    height: "calc(100vh - 141px)",
    textAlign: "center",
  },
  dashboard: {
    paddingTop: theme.spacing(2),
  },
}));
