// Packages
import React from "react";
import { Button, Divider } from "@aws-amplify/ui-react";

// Styles
import { useStyles } from "./styles";

export default function SignInHeader({federatedSignIn, signedOut}) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <img src="/aais-logo-with-letters-1800.png" alt="" width="90%" />
      <div className={classes.sectionHeader}>
        {signedOut && (
          <div>As a security best practice, you have been logged out due to 30 minutes of inactivity.<br></br><div></div></div>
        )
        }
        Sign in to your account.
      </div>
      <div className={classes.signInWrapper}>
        <Button variation={"primary"}
                isFullWidth={true}
                onClick={federatedSignIn}>
          AAIS Employees
        </Button>
      </div>
      <Divider size="small" label="or" className={classes.divider} />
    </div>
  );
}
