/**
 * @description The enumeration of the user phone types
 * @since 1.0.0
 * @enum {String}
 * @readonly
 * @public
 */
const PHONE_TYPE = {
  /**
   * @constant {String}
   * @description The office phone number
   * @since 1.0.0
   * @enum
   */
  OFFICE: {
    key: "office",
    title: "Office",
    description: "Office",
  },
  /**
   * @constant {String}
   * @description The fax phone number
   * @since 1.0.0
   * @enum
   */
  FAX: {
    key: "fax",
    title: "Fax",
    description: "Fax",
  },
  /**
   * @constant {String}
   * @description The Personal Cell phone number
   * @since 1.0.0
   */
  PERSONAL_CELL: {
    key: "personalCell",
    title: "Person Cell",
    description: "Person Cell",
  },
  /**
   * @constant {String}
   * @description The Work Cell phone number
   * @since 1.0.0
   */
  WORK_CELL: {
    key: "workCell",
    title: "Work Cell",
    description: "Work Cell",
  },
};

export default PHONE_TYPE;
