// Packages
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  grid: {
    flexGrow: 1,
  },
  cardHeader: {
    backgroundColor: "#ececfa",
  },
  paper: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    padding: theme.spacing(0.5),
    width: "100%",
  },
  internalUse: {
    color: theme.palette.grey[600],
    paddingTop: theme.spacing(1),
  },
}));
