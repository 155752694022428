// Packages
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: 'var(--amplify-components-authenticator-form-padding)',
    paddingBottom: "0",
  },
  divider: {
    paddingTop: 'var(--amplify-components-authenticator-form-padding)'
  },
  signInWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  sectionHeader: {
    color: 'var(--deepSquidInk)',
    marginBottom: '24px',
    fontSize: '18px',
    fontWeight: '500',
  }
}));
