/**
 * @description This is a component to show or hide automatically a tooltip
 * @module components/OverFlowToolTip
 */

import React, { useRef } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { useStyles } from "./styles";
import { useTooltipHoverStatus } from "./useTooltipHoverStatus";

const OverflowTooltip = ({ children, title, ...rest }) => {
  // Create Ref
  const textElementRef = useRef();
  const classes = useStyles();
  const hoverStatus = useTooltipHoverStatus(textElementRef);
  const tooltipProps = {
    title,
    interactive: true,
    ...rest
  };

  return (
    <Tooltip
      {...tooltipProps}
      disableHoverListener={!hoverStatus}
      classes={{tooltip: classes.tooltip}}
    >
      <div
        ref={textElementRef}
        className={classes.content}
      >
        {children}
      </div>
    </Tooltip>
  );
};

export default OverflowTooltip;
