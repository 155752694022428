// Packages
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#5C6771",
    width: "100%",
    maxHeight: "50px",
    minHeight: "50px",
    alignItems: 'center',
    color: "#204286",
    borderRadius: 0,
    boxShadow: "none",
    display: "flex",
    flex: 1,
    bottom: 0,
    position: "static",
    marginTop: "20px"
  },
  right: {
    width: "50%",
    display: "flex",
    marginBottom: "auto",
    justifyContent: "flex-end",
    paddingRight: 35
  },
  link: {
    color: "white",
    fontSize: "14px",
    textDecoration: "none !important",
    marginLeft: "5px",
    marginRight: "5px",
    cursor: "pointer",
    transition: "color 0.2s",
    "&:hover": {
        color: "#0097AC"
    }
  },
  copyrightContainer: {
    width: "50%",
    paddingLeft: 35
  },
  copyright: {
    color: 'white',
    fontSize: "14px",
  },
  separator: {
    '&::after': {
      content: '"|"',
      color: "white",
      fontSize: "13px",
    },
  },
  container: {
    marginBottom: "3px",
  }
}));
