// Packages
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  loadingRow: {
    padding: 0,
    height: 0,
  },
  loadingCell: {
    padding: 0,
  },
  roleKeySelector: {
    minWidth: 125,
  },
  disabledRow: {
    backgroundColor: theme.palette.secondary.light,
  },
  counter: {
    transform: "translateY(50%)",
  },
  toolbar:{
    padding: 0,
    margin: 0,
  }
}));
