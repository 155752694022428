/**
 * @description This is the User Card component that is used
 * throught the application. It is used to quickly show a user
 * @author Findlay Clarke <findlayc@aaisonline.com>
 * @since 1.0.0
 * @module components/UserCard
 */

// Packages
import React, { useState, useContext, useEffect } from "react";

// MUI Components
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import MaterialUILink from "@material-ui/core/Link";
import Avatar from "@material-ui/core/Avatar";
import TextField from "@material-ui/core/TextField";
import LinearProgress from "@material-ui/core/LinearProgress";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";

// MUI Icons
import DeleteIcon from "@material-ui/icons/Delete";

// Data
import PHONE_TYPE from "enums/PHONE_TYPE";

// Styles
import { useStyles } from "./styles";

// Util
import * as userUtil from "util/userUtil";

// Services
import * as UserApi from "apis/UserApi";

// Context
import ReloadContext from "contexts/ReloadContext";
import MeContext from "contexts/MeContext";

/**
 * @description The functional component for the User Card
 * @since 1.0.0
 * @public
 */
export default function UserInfo(props) {
  const { user } = props;
  const classes = useStyles();
  const reloadScreen = useContext(ReloadContext);
  const me = useContext(MeContext);
  const [loading, setLoading] = useState(false);
  let iAmAdmin = false;
  let canUpdate = false;
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [secondaryEmail, setSecondaryEmail] = useState(user.secondaryEmail);
  const [snack, setSnack] = React.useState({
    open: false,
    note: "snack set",
  });
  if('apps' in me && me['apps'].length > 0){
    me['apps'].map((item) => {
      if('roleKey' in item && item['roleKey'] && item['roleKey'].toLowerCase() === 'admin'){
        iAmAdmin = true;
        canUpdate = true;
      }
    });
  }
  if(me['userId'] === user.userId){
    canUpdate = true;
  }

  const phone = userUtil.getPhoneNumber(user, PHONE_TYPE.OFFICE);
  const initPhoneNumber = phone && phone.phoneNumber ? phone.phoneNumber : "";
  const initExtension = phone && phone.extension ? phone.extension : "";
  const countryCode = "+1";

  const [phoneNumber, setPhoneNumber] = useState(initPhoneNumber);
  const [phoneExtension, setPhoneExtension] = useState(initExtension);

  useEffect(() => {
    console.log("snackbar changed", snack);
  }, [snack]);

  async function handleUpdate() {
    console.log(
      "handle called",
      firstName,
      lastName,
      phoneNumber,
      phoneExtension
    );
    setLoading(true);

    //update name
    if (!firstName) {
      console.error("firstName not found showing error");
      updateFailed("Firstname");
    }

    if (!lastName) {
      updateFailed("Lastname");
    }

    if (
      user.firstName !== firstName ||
      user.lastName !== lastName ||
      user.secondaryEmail !== secondaryEmail
    ) {
      await UserApi.updateName(
        user.userId,
        firstName,
        lastName,
        secondaryEmail
      );
    }

    const oldPhone = userUtil.getPhoneNumber(user, PHONE_TYPE.OFFICE);

    if (!phoneNumber && phoneExtension) {
      updateFailed("Phone");
    }

    if (
      !oldPhone ||
      phoneNumber !== oldPhone.phoneNumber ||
      phoneExtension !== oldPhone.extension
    ) {
      if (!oldPhone || !oldPhone.phoneNumberId) {
        await UserApi.addPhoneNumber(
          user.userId,
          PHONE_TYPE.OFFICE,
          phoneNumber,
          countryCode,
          phoneExtension
        );
      } else {
        await UserApi.updatePhoneNumber(
          user.userId,
          PHONE_TYPE.OFFICE,
          oldPhone.phoneNumberId,
          phoneNumber,
          countryCode,
          phoneExtension
        );
      }
    }

    reloadScreen();
    setLoading(false);
  }

  function handleCancel() {
    setFirstName(user.firstName);
    setLastName(user.lastName);
    setSecondaryEmail(user?.secondaryEmail);

    const phone = userUtil.getPhoneNumber(user, PHONE_TYPE.OFFICE);
    const initPhoneNumber =
      phone && phone.phoneNumber ? phone.phoneNumber : null;
    const initExtension = phone && phone.extension ? phone.extension : null;
    setPhoneNumber(initPhoneNumber);
    setPhoneExtension(initExtension);
  }

  function updateFailed(sectionTitle) {
    console.log("snack bar open", sectionTitle);
    setSnack({
      open: true,
      note: `${sectionTitle} cannot be empty`,
    });
    console.log("snack done bar opening", sectionTitle);
  }

  async function handleDelete() {
    setLoading(true);
    const phone = userUtil.getPhoneNumber(user, PHONE_TYPE.OFFICE);

    if (!user.userId || !phone.phoneNumberId) {
      return;
    }

    await UserApi.deletePhoneNumber(
      user.userId,
      PHONE_TYPE.OFFICE,
      phone.phoneNumberId
    );

    setPhoneNumber("");
    setPhoneExtension("");

    reloadScreen();
    setLoading(false);
  }

  function handleUploadPicture() {
    if(canUpdate !== true){
      return;
    }
    const input = document.createElement("input");
    input.type = "file";
    const dataTestId = document.createAttribute("data-testid");
    dataTestId.value = "uploadFileInput";
    input.setAttributeNode(dataTestId);

    input.onchange = (e) => {
      const file = e.target.files[0];

      if (!file) return;

      setLoading(true);
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = async (readerEvent) => {
        try {
          const content = readerEvent.target.result;
          const imageInBase64 = content.split(",")[1];
          const contentType = file.type;
          const fileExtension = contentType.split("/")[1];

          await UserApi.changeProfilePicture(
            user.userId,
            contentType,
            fileExtension,
            imageInBase64
          );
          reloadScreen();
        } catch (error) {
          alert("Can't procees picture info. Please try a different picture");
          console.error(error);
        } finally {
          setLoading(false);
        }
      };
    };

    input.click();
  }

  function getNameSection() {
    return (
      <React.Fragment>
        <Typography variant="h5" className={classes.sectionTitle}>
          Name
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6}>
            <TextField
              label="First"
              disabled={!canUpdate}
              value={firstName}
              data-test="firstName"
              onChange={(event) => setFirstName(event.target.value)}
              margin="normal"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <TextField
              label="Last"
              value={lastName}
              disabled={!canUpdate}
              data-test="lastName"
              onChange={(event) => setLastName(event.target.value)}
              margin="normal"
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }

  function getEmailSection() {
    return (
      <React.Fragment>
        <Typography variant="h5" className={classes.sectionTitle}>
          Email
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h5" className={classes.email}>
              <MaterialUILink href={`mailto:${user.email}`} className={classes.link}>
                {user.email}
              </MaterialUILink>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <TextField
              label="Secondary Email"
              disabled={!canUpdate}
              value={secondaryEmail}
              data-test="secondary-email"
              onChange={(event) => setSecondaryEmail(event.target.value)}
              margin="normal"
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }

  function getPhoneSection() {
    return (
      <React.Fragment>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h5" className={classes.sectionTitle}>
              Phone
            </Typography>
          </Grid>
          <Grid item>
            <IconButton color="primary" onClick={handleDelete}>
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} md={2}>
            <TextField
              label="Country Code"
              value={countryCode}
              margin="normal"
              variant="standard"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={12} md={7}>
            <TextField
              label="Phone"
              aria-label="Phone"
              inputProps={{ "data-test": "Phone" }}
              value={phoneNumber}
              onChange={(event) => setPhoneNumber(event.target.value)}
              margin="normal"
              variant="standard"
              disabled={!canUpdate}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              label="Extension"
              aria-label="Extension"
              id="margin-none"
              inputProps={{ "data-test": "Extension" }}
              placeholder="1234"
              value={phoneExtension}
              onChange={(event) => setPhoneExtension(event.target.value)}
              margin="normal"
              variant="standard"
              disabled={!canUpdate}
              fullWidth
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }

  if (!user) return <React.Fragment />;

  return (
    <React.Fragment>
      {loading && <LinearProgress />}
      {
        <Snackbar
          data-test="snackbar"
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={snack.open}
          onClose={(e, reason) => {
            //TODO: this seems to be bug with material UI. the snackbar closes almost
            //immediately with clickaway event. not sure why
            if (reason !== "clickaway") setSnack({ open: false, note: reason });
          }}
          autoHideDuration={5000}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span>{snack.note}</span>}
        />
      }
      <Paper className={classes.paper}>
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button variant="outlined" color="primary" onClick={handleCancel}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" onClick={() => handleUpdate()} disabled={!canUpdate}>
              Update
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid
            item
            container
            alignItems="center"
            xs={12}
            lg={2}
            xl={1}
            className={classes.left}
            direction="column"
          >
            <Grid item>
              <Avatar
                className={classes.avatar}
                alt={userUtil.getDisplayName(user)}
                src={user.picture}
                onClick={() => handleUploadPicture() }
                disabled={!canUpdate}
              >
                {!user.picture ? userUtil.getInitials(user) : null}
              </Avatar>
            </Grid>
            <Grid item>
              <Button
                className={classes.uploadButton}
                variant="outlined"
                color="primary"
                disabled={!canUpdate}
                onClick={() => handleUploadPicture()}
              >
                Upload Picture
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={10} xl={11} className={classes.right}>
            {getNameSection()}
            <Divider className={classes.divider} />
            {getEmailSection()}
            <Divider className={classes.divider} />
            {getPhoneSection()}
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
}
