import { Auth } from "aws-amplify";

export const ANALYTICS_PAGE_VIEW_CONFIG = {
  enable: true,
  eventName: "pageView",
  attributes: async () => {
    return {
      userId: (await Auth.currentSession()).idToken.payload.sub,
    };
  },
  type: "SPA",
  provider: "AWSPinpoint",
  getUrl: () => {
    return (
      window.location.origin + window.location.pathname + window.location.hash
    );
  },
};

export const ANALYTICS_EVENT_CONFIG = {
  enable: true,
  events: ["click"],
  // OPTIONAL, the prefix of the selectors, by default is 'data-amplify-analytics-'
  // in order to avoid collision with the user agent, according to https://www.w3schools.com/tags/att_global_data.asp
  // always put 'data' as the first prefix
  selectorPrefix: "data-aws-pp-",
  // OPTIONAL, the service provider, by default is the AWS Pinpoint
  provider: "AWSPinpoint",
  attributes: async () => {
    return {
      userId: (await Auth.currentSession()).idToken.payload.sub,
      hash: window.location.hash,
    };
  },
};
