/**
 * @description HealthStatusCheckApi Module. This will have all the API's needed
 * to interact with the backend API's for the HealthStatusCheck
 * @module apis/HealthStatusCheckApi
 * @since 1.0.0
 * @author Findlay Clarke <fclarke@aaisonline.com>
 * @public
 */

import * as UserApi from "apis/UserApi";
import * as AssetApi from "apis/AssetApi";

/**
 * @description Checks Login Functionality
 * @since 1.0.0
 * @author Findlay Clarke <fclarke@aaisonline.com>
 */

/**
 * Checks that the user can still login. Even though
 * they are logged in
 */
export async function checkLoginHealth() {
  try {
    await UserApi.getMe();
    return true;
  } catch (e) {
    console.log(e);
  }
  return false;
}

/**
 * Checks download
 * @returns
 */
export async function checkDownloadHealth() {
  try {
    //doesnt really matter what is being searched
    await AssetApi.search("justABunchOfGiberish");
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}
