// The commented code responds to story US99853

// Packages
import React, { useState } from "react";
import { useLocation } from "react-router-dom";

// MUI Components
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

// MUI Icons
import ContactMailIcon from "@material-ui/icons/ContactMail";
// import AlertIcon from "@material-ui/icons/AddAlert";

// Styles
import { useStyles } from "./styles";

export default function UserTabsSection(props) {
  const { infoTab } = props;
  const classes = useStyles();
  const location = useLocation();
  // const navigate = useNavigate();
  // const { userId } = useParams();
  let initial = 0;
  if (location.pathname.endsWith("bulletins")) {
    initial = 1;
  }

  const [selectedTab, setTab] = useState(initial);

  // function handleChange(event, newSelectedTab) {
  //   if (selectedTab === 0) {
  //     //FIXME: not sure why this works
  //     navigate(`/users/${userId}/bulletins`, { replace: true });
  //   } else {
  //     navigate(`/users/${userId}`, { replace: true });
  //   }
  //   setTab(newSelectedTab);
  // }

  return (
    <React.Fragment>
      <Paper className={classes.root} elevation={0}>
        <Tabs
          value={selectedTab}
          // onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered={true}
        >
          <Tab label="Info" icon={<ContactMailIcon />} />
          {/* <Tab label="Notification Preferences" icon={<AlertIcon />} /> */}
        </Tabs>
      </Paper>
      <React.Fragment>{infoTab}</React.Fragment>
      {/* {selectedTab === 0 && <React.Fragment>{infoTab}</React.Fragment>}
      {selectedTab === 1 && <React.Fragment>{bulletinsTab}</React.Fragment>} */}
    </React.Fragment>
  );
}
