import "react-app-polyfill/ie11"; //IE 11 support
import "react-app-polyfill/stable"; //IE 11 Support
import React from "react";

import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { createRoot } from "react-dom/client";
import { ThemeProvider } from "@aws-amplify/ui-react";
import { AmplifyTheme } from "./styles/AmplifyTheme";
const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<ThemeProvider theme={AmplifyTheme}> <App tab="home" /></ThemeProvider>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

window.onUsersnapCXLoad = function(api) {
    api.init();
};
const script = document.createElement('script');
script.defer = 1;
script.src = 'https://widget.usersnap.com/global/load/0a9674ea-c8c6-4ec2-b0d6-c70702dc973e?onload=onUsersnapCXLoad';
document.getElementsByTagName('head')[0].appendChild(script);