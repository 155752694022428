// Packages
import React, { useState, useEffect, useContext } from "react";

// MUI Components
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CardHeader from "@material-ui/core/CardHeader";
import Paper from "@material-ui/core/Paper";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Link } from "@material-ui/core";

// Styles
import { useStyles } from "./styles";

//Context
import LandingPagesContext from "contexts/LandingPagesContext";
import ProductsContext from "contexts/ProductsContext";

export default function NewlyAvailableCard(props) {
  const classes = useStyles();
  const [allNotifications, setAllNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [orderedNotifications, setOrderedNotifications] = useState(null);

  const landingPages = useContext(LandingPagesContext);
  const products = useContext(ProductsContext);

  useEffect(() => {
    const newNotifications = allNotifications;
    if(landingPages && (landingPages.length > 0)){
      landingPages.map((landingPage) => {
        newNotifications.push(landingPage);
      });
      setLoading(true);
      setOrderedNotifications(orderNotification(newNotifications));
      setLoading(false);
    }
  }, [landingPages]);
  useEffect(() => {
    const newNotifications = allNotifications;
    if(products && (products.length > 0)){
      products.map((product) => {
        newNotifications.push(product);
      });
      setLoading(true);
      setOrderedNotifications(orderNotification(newNotifications));
      setLoading(false);
    }
  }, [products]);

  function orderNotification(inputNotifications){
    const newNotifications = [];
    const foundNames = [];
    inputNotifications.map((notification) => {
      if(notification.ready && notification.ready === true && !foundNames.includes(notification.name) ){
        newNotifications.push({'name': notification.name, 'updated': notification.updated, 'type': notification.landingPageId ? 'Landing Page' : 'Product' });
        foundNames.push(notification.name);
      }
    });
    const newOrderedNotification = newNotifications.sort((a, b) => {
      return(Date.parse( b.updated ) - Date.parse( a.updated ));
    });
    return newOrderedNotification; 
  }

  return (
    <React.Fragment>
        <Grid item xs={12} className={classes.grid}>
            <Card className={classes.card}>
                <CardHeader
                    className={classes.cardHeader}
                    title={`Content Rollout Status`}
                />
                <CardContent className={classes.cardContent}>
                  <Paper className={classes.paper} elevation={0}>
                    <Typography className={classes.text}>
                      <div className={classes.listContainer}>
                      {orderedNotifications &&!loading && ("Content is being rolled out in phases to AAIS users. The first phase is forms. Forms are available for the following lines of business:")}
                        <ul className={classes.list}>
                          {orderedNotifications &&!loading && orderedNotifications.map((notification, index) => {

                            return (<li className={classes.li} key={index}>{notification.name}</li>);
                          })}
                           {!orderedNotifications && loading && (<LinearProgress />)}
                        </ul>
                      </div>
                    </Typography>
                  </Paper>
                </CardContent>
            </Card>
        </Grid>
    </React.Fragment>
  );
}