/**
 * @description This is the User Screen module that is used
 * to serve the User Screen View
 * @author Findlay Clarke <findlayc@aaisonline.com>
 * @since 1.0.0
 * @module screens/UserScreen
 */

// Packages
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

// Components
import SummaryBar from "components/SummaryBar";
import UserInfo from "components/UserInfo";
import UserTabsSection from "components/UserTabsSection";
import OrgsTable from "components/OrgsTable";
import Bulletins from "components/Bulletins";
import ReloadContext from "contexts/ReloadContext";

// Styles
import { useStyles } from "./styles";

// Util
import { getDisplayName } from "util/userUtil";

// Services
import * as UserApi from "apis/UserApi";

export default function UserScreen(props) {
  const [toggleToRerender, setToggleToRerender] = useState(true);
  const {setToggleToRerenderTop} = props;

  const classes = useStyles();

  const { userId } = useParams();

  const [user, setUser] = useState(UserApi.getFromCache(userId));

  useEffect(() => {
    window.gtag("event", "page_view", {
      page_title: "User Screen",
      page_path: `${window.location.pathname}${window.location.hash}`,
      path_location: window.location.href,
      send_to: `${process.env.REACT_APP_GA}`
    });
  }, [userId]);

  useEffect(() => {
    UserApi.get(userId).then((user) => {
      setUser(user);
    });
  }, [userId, toggleToRerender]);

  /**
   * @description Used to refetch the data for the screen
   * @since 1.0.0
   * @author Findlay Clarke <findlayc@aaisonline.com>
   * @inner
   */
  function updateScreen() {
    setToggleToRerender(!toggleToRerender);
    setToggleToRerenderTop(true);
  }

  const displayName = getDisplayName(user);

  return (
    <React.Fragment>
      <div className={classes.container}>
        <ReloadContext.Provider value={updateScreen}>
          <SummaryBar hideExpand={true} expanded={false} title={displayName}>
          </SummaryBar>
          <UserTabsSection
            infoTab={<UserInfo user={user} />}
            orgsTab={<OrgsTable orgIds={user.orgs} userId={user.userId} />}
            bulletinsTab={<Bulletins user={user} />}
          />
        </ReloadContext.Provider>
      </div>
    </React.Fragment>
  );
}
