// Packages
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh"
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2)
  },
  routes: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
}));
