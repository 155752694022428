// Packages
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    width: "100%",
  },
  chip: {
    margin: theme.spacing(0.25),
  },
  section: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  currentIcon: {
    color: "#4caf50",
  },
  notCurrentIcon: {
    color: "#dc004e",
  },
  menuItemText: {
    paddingLeft: 5,
    verticalAlign: "bottom",
  },
  effectiveIcon: {
    paddingTop: "5px",
    paddingBottom: "5px"
  },
  ul: {
    paddingTop: "5px",
    listStyle: 'none',
    listStyleType: "none"
  },
  li: {
    paddingTop: "5px",
    paddingBottom: "5px",
    listStyle: "none",
    listStyleType: "none"
  },
  ol: {
    listStyle: "none",
    listStyleType: "none"
  }
}));

export const popperStyles = (theme) => ({
  popper: {
    width: "fit-content",
    placement: 'bottom-start'
  }
});