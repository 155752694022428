import { AaisTheme } from "./AaisTheme";

export const AmplifyTheme = {
  name: 'my-theme',
  tokens: {
    components: {
      authenticator: {
        router : {
          boxShadow: {
            value: '1px 1px 4px 0 #1f428752'
          },
          borderStyle: {
            value: 'none'
          }
        },
        modal: {
          backgroundColor: {
            value: AaisTheme.palette.primary.contrastText
          }
        }
      },
      button: {
        primary: {
          backgroundColor: {
            value: AaisTheme.palette.primary.main
          },
          _hover: {
            backgroundColor: {
              value: AaisTheme.palette.primary.main
            },
          }
        },
        link: {
          backgroundColor: {
            value: AaisTheme.palette.primary.contrastText
          },
          color: {
            value: AaisTheme.palette.primary.main
          },
          _hover: {
            backgroundColor: {
              value: AaisTheme.palette.primary.contrastText
            },
            color: {
              value: AaisTheme.palette.primary.main
            }
          },
        },
      },
    }
  },
  };
