// Packages
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  list: {
    flexGrow: 1,
    margin: "0"
  },
  listContainer: {
    fontSize: "16px",
    height: "100%",
    overscrollBehaviorY: "contain",
  },
  cardHeader: {
    backgroundColor: "#5C666F",
    textAlign: "center",
    color: "white"
  },
  cardContent: {
    maxHeight: 160,
    height: "110%",
    overflowY: "auto",
    textAlign: "left"
  },
  link: {
    textDecoration: "none !important",
    cursor: "pointer",
    transition: "color 0.2s",
    "&:hover": {
        color: "#0097AC"
    }
  },
  li: {
    fontSize: "12px",
  },
  text: {
    fontSize: "16px",
    overflowY: "auto",
    height: "100%",
  },
  card: {
    height: "auto",
    
  },
}));
