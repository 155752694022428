// Packages
import React, { useState, useEffect } from "react";

// MUI Components
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";


import DialogTitle from "@material-ui/core/DialogTitle";
import { LinearProgress } from "@material-ui/core";
import MoreInfoTable from "components/MoreInfoTable";
import FileDownloadIcon from '@material-ui/icons/CloudDownload';
import ClearIcon from "@material-ui/icons/Clear";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

//US125518 temporarily comment out data that is not ready to display

// Styles
import { useStyles } from "./styles";

// Services
import * as AssetApi from "apis/AssetApi";

export default function SingleResult(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [results, setResults] = useState(null);
  const [indexId, setIndexId] = useState(null);
  const [updatedResults, setUpdatedResults] = useState(null);
  const [showOpenIcon, setShowOpenIcon] = useState(false);
  const [mainBlob, setMainBlob] = useState();
  const [resultsReady, setResultsReady] = useState(false);

  const checkPDFext = (item) => item.extension === ".pdf";
  useEffect(() => {
    
    async function fetchData() {
        setIndexId(props.indexId);
        if(indexId){
            setLoading(true);
            setResults(null);

            const tempResults = await AssetApi.get(indexId);
            setResults(tempResults);
            setLoading(false);
            if(tempResults._source['files']){
              setShowOpenIcon(tempResults._source['files'].some(checkPDFext));
            }
        }
    }
    fetchData();
  }, [props.indexId, indexId]);

  useEffect(() => {
    async function setBlob() {
      if( updatedResults){
        let csvData =  updatedResults[0]['title'].replaceAll(",", ";") + "\n";      
        csvData = csvData + updatedResults[0]['category'].replaceAll(",", ";") + "\n" ;

        csvData = csvData + "\nLine,State,From,To,AAIS File Number,Serff Tracking Number\n";
        //csvData = csvData + "\nLine,State,From,To,Bulletin Number,AAIS File Number,Serff Tracking Number,State File Number,Most Recently Replaced\n"; //US125518
        await updatedResults.map( (item) => {
          let thisBulletin = "-";
          if(item['bulletin'].length > 0){
            thisBulletin = '';
            item['bulletin'].map( (bulletin) => {
              if(bulletin && bulletin['bulletinNumber']){
                thisBulletin = thisBulletin + JSON.stringify(bulletin['bulletinNumber']).replaceAll('[', '').replaceAll(']', '').replaceAll('"', '') + '; ';
              }
            });
            thisBulletin = thisBulletin.slice(0, -2);
          }

          csvData = csvData + item['line'] + ',' + item['state'] + ',' + item['from'] + ',' + item['to'] + ',"' + item['fileNum'] + '","' + item['serff'] + '"' +"\n";
          //csvData = csvData + item['line'] + ',' + item['state'] + ',' + item['from'] + ',' + item['to'] + ',"' + thisBulletin + '","' + item['fileNum'] + '","' + item['serff'] + '","' + item['stateFile'] + '","' + item['replaced'] + '"' +"\n"; //US125518
        });

        const csvFile = new Blob([csvData], {type: 'text/csv'});
        setMainBlob(csvFile);
        setResultsReady(true);
      }
    }
    setBlob();
  }, [showOpenIcon, updatedResults]);

  return (
    <Dialog open={true} onClose={props.handleClose} maxWidth="xl" fullWidth>
      <DialogTitle className={classes.dialogTitle}>{props.title} {showOpenIcon && <OpenInNewIcon className={classes.openInNewIconInner} onClick={ async () => {
                    await props.setUpNewTabLink(results._source['files']);
                    }}></OpenInNewIcon>}
          { /*  eslint-disable-next-line  */ }
          {resultsReady && <a className={classes.csvLink} href={URL.createObjectURL(mainBlob)} download={props.title.replace(/[^a-zA-Z0-9\s_\(\)]/gi, '') + '.csv'}><FileDownloadIcon
            className={classes.rightContentDownload}
            data-test={`singleResult-item-download-icon`}
          ></FileDownloadIcon></a>}
          <ClearIcon className={classes.rightContent} onClick={props.handleClose} ></ClearIcon>
        </DialogTitle> 
      <DialogContentText className={classes.category}>{props.category}</DialogContentText>
      <DialogContent className={classes.content}>
        {loading && <LinearProgress />}
        {results && (
            <MoreInfoTable 
                infoEntries={results._source} 
                handleClose={props.handleClose}
                updateTopResults={setUpdatedResults}
                
            
            ></MoreInfoTable>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary" className={classes.closeButton}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}