import { Auth } from "aws-amplify";

export const AMPLIFY_CONFIG = {
  Auth: {
    identityPoolId: process.env.REACT_APP_AMPLIFY_AUTH_IDENTITY_POOL_ID,
    region: process.env.REACT_APP_AMPLIFY_AUTH_REGION,
    userPoolId: process.env.REACT_APP_AMPLIFY_AUTH_USER_POOL_ID,
    userPoolWebClientId:
      process.env.REACT_APP_AMPLIFY_AUTH_USER_POOL_WEB_CLIENT_ID,
    mandatorySignIn: true,
    oauth: {
      label: "AAIS Employees",
      domain: process.env.REACT_APP_AMPLIFY_AUTH_OAUTH_DOMAIN,
      redirectSignOut:
        process.env.REACT_APP_AMPLIFY_AUTH_OAUTH_REDIRECT_SIGN_OUT,
      redirectSignIn: process.env.REACT_APP_AMPLIFY_AUTH_OAUTH_REDIRECT_SIGN_IN,
      responseType: "code",
      scope: ["email", "profile", "openid"],
    },
  },
  API: {
    endpoints: [
      {
        name: "ORG",
        region: process.env.REACT_APP_AMPLIFY_API_ENDPOINT_REGION,
        endpoint: process.env.REACT_APP_AMPLIFY_API_ENDPOINT_ORG_URL,
        custom_header: async () => {
          return {
            Authorization: (await Auth.currentSession()).idToken.jwtToken,
          };
        },
      },
      {
        name: "USER",
        region: process.env.REACT_APP_AMPLIFY_API_ENDPOINT_REGION,
        endpoint: process.env.REACT_APP_AMPLIFY_API_ENDPOINT_USER_URL,
        custom_header: async () => {
          return {
            Authorization: (await Auth.currentSession()).idToken.jwtToken,
          };
        },
      },
      {
        name: "ASSET",
        region: process.env.REACT_APP_AMPLIFY_API_ENDPOINT_REGION,
        endpoint: process.env.REACT_APP_AMPLIFY_API_ENDPOINT_ASSET_URL,
        custom_header: async () => {
          return {
            Authorization: (await Auth.currentSession()).idToken.jwtToken,
          };
        },
      },

      {
        name: "MDS",
        region: process.env.REACT_APP_AMPLIFY_API_ENDPOINT_REGION,
        endpoint: process.env.REACT_APP_AMPLIFY_API_ENDPOINT_MDS_URL,
        custom_header: async () => {
          return {
            Authorization: (await Auth.currentSession()).idToken.jwtToken,
          };
        },
      },
      {
        name: "PRODUCT",
        region: process.env.REACT_APP_AMPLIFY_API_ENDPOINT_REGION,
        endpoint: process.env.REACT_APP_AMPLIFY_API_ENDPOINT_PRODUCT_URL,
        custom_header: async () => {
          return {
            Authorization: (await Auth.currentSession()).idToken.jwtToken,
          };
        },
      },
      {
        name: "LANDINGPAGE",
        region: process.env.REACT_APP_AMPLIFY_API_ENDPOINT_REGION,
        endpoint: process.env.REACT_APP_AMPLIFY_API_ENDPOINT_LANDINGPAGE_URL,
        custom_header: async () => {
          return {
            Authorization: (await Auth.currentSession()).idToken.jwtToken,
          };
        },
      },
    ],
  },
  Analytics: {
    AWSPinpoint: {
      appId: process.env.REACT_APP_AMPLIFY_PINPOINT_APP_ID,
      region: process.env.REACT_APP_AMPLIFY_API_ENDPOINT_REGION,
      mandatorySignIn: true,
    },
  },
};
