/**
 * @description The enumeration of the Plans
 * @since 1.0.0
 * @enum {String}
 * @readonly
 * @public
 */


const Plans = {
    BY_PERIL: {
      key: "BY_PERIL",
      title: "By Peril",
      description:"",
    },
    BY_PERIL_EXEMPT: {
        key: "BY_PERIL_EXEMPT",
        title: "By Peril Exempt",
        description:
          "",
    },
    COMPOSITE: {
        key: "COMPOSITE",
        title: "Composite",
        description:
          "",
    },
    EXEMPT: {
        key: "EXEMPT",
        title: "Exempt",
        description:
          "",
    },
    COMPOSITE_EXEMPT: {
        key: "COMPOSITE_EXEMPT",
        title: "Composite Exempt",
        description:
          "",
    },
    ALTERNATE: {
        key: "ALTERNATE",
        title: "Alternate",
        description:
          "",
    }
};
export default Plans;
