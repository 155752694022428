/**
 * @description The enumeration of the Programs
 * @since 1.0.0
 * @enum {String}
 * @readonly
 * @public
 */


const Programs = {
    Commercial_Liability_Program: {
      key: "Commercial_Liability_Program",
      keyAPI: "Commercial Liability Program",
      title: "Commercial Liability Program",
      description: "",
      line: "CL",
    },
    Owners_Contractors_Protective_Program: {
        key: "Owners_Contractors_Protective_Program",
        keyAPI: "Owners Contractors Protective Program",
        title: "Owners & Contractors Protective Program",
        description: "",
        line: "CL",
    },
    Farmowners_Program: {
        key: "Farmowners_Program",
        keyAPI: "Farmowners Program",
        title: "Farmowners Program",
        description: "",
        line: "FO",
    },
    Home_Based_Business_Program: {
        key: "Home_Based_Business_Program",
        keyAPI: "Home Based Business Program",
        title: "Home-Based Business Program",
        description: "",
        line: "FO",
    },
    Dwelling_Properties_Program: {
        key: "Dwelling_Properties_Program",
        keyAPI: "Dwelling Properties Program",
        title: "Dwelling Properties Program",
        description: "",
        line: "DP",
    },
    Landlords_Premises_Only_Liability_Coverage_Option: {
        key: "Landlords_Premises_Only_Liability_Coverage_Option",
        keyAPI: "Landlords Premises Only Liability Coverage Option",
        title: "Landlord's Premises Only Liability Coverage Option",
        description: "",
        line: "DP",
    },
    Personal_Farm_Personal_Liability_Program: {
        key: "Personal_Farm_Personal_Liability_Program",
        keyAPI: "Personal Farm Personal Liability Program",
        title: "Personal / Farm Personal Liability Program",
        description: "",
        line: "PPL",
    },
    Premises_Only_Liability_Program: {
        key: "Premises_Only_Liability_Program",
        keyAPI: "Premises Only Liability Program",
        title: "Premises Only Liability Program",
        description: "",
        line: "PPL",
    },
    Personal_Umbrella_Monoline: {
        key: "Personal_Umbrella_Monoline",
        keyAPI: "Personal Umbrella Monoline",
        title: "Personal Umbrella - Monoline",
        description: "",
        line: "PUP",
    },
    Personal_Umbrella_Packaged: {
        key: "Personal_Umbrella_Packaged",
        keyAPI: "Personal Umbrella Packaged",
        title: "Personal Umbrella - Packaged",
        description: "",
        line: "PUP",
    }
};
export default Programs;
