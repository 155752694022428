// Packages
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 0,
    backgroundColor: theme.palette.grey[100],
    position: "relative"
  },
  tab: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    backgroundColor: "red",
  },
  topSection: {
    marginBottom: theme.spacing(2),
  },
  back: {
    position: "absolute",
    top: "18px",
    left: "25px",
    zIndex: "2"
  },
  tooltip: {
    fontSize: theme.typography.pxToRem(15),
  },
}));
