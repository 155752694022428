// Packages
import React, { useState, useEffect } from "react";

// MUI Components
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DialogContentText from "@material-ui/core/DialogContentText";
import FormLabel from "@material-ui/core/FormLabel";


// Styles
import { useStyles } from "./styles";

// Services

export default function FilePicker(props) {
  const classes = useStyles();

  return ( 
    <Dialog open={true} onClose={props.handleClose} maxWidth="xl" fullWidth>
      <FormControl className={classes.invalidContent} maxWidth="xl" fullWidth>
        <FormLabel id="files-label-helper" maxWidth="xl" fullWidth>No file is available for preview. Please download the publication to view other available file types, such as a Word document.</FormLabel>
      </FormControl>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
