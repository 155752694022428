// Packages
import React, { useState, useEffect } from "react";

// MUI Components
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CardHeader from "@material-ui/core/CardHeader";
import Paper from "@material-ui/core/Paper";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Link } from "@material-ui/core";

// Services
import * as AssetApi from "apis/AssetApi";

// Styles
import { useStyles } from "./styles";

// Data
import LINES from "enums/LINES";

export default function MyRecentProductsCard(props) {
  const classes = useStyles();
  const [manifests, setManifests] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getHistory();
  }, [props.lineKey]);

  async function getHistory() {
    const data = await AssetApi.getUserManifestMetaData(props.userId);
    setManifests(data);
    setLoading(false);
  }

  function getList() {
    let lineKeys = [];
    manifests.forEach((manifest) => {
      if (manifest.request.products)
        lineKeys = lineKeys.concat(manifest.request.products);
    });

    lineKeys = [... new Set(lineKeys)]; //Removing duplicates values
    lineKeys.splice(5); // only show the last 5

    return lineKeys.map((lineKey) => (
      <Typography key={lineKey}>
        <Link href={`#/lines/${lineKey}`} className={classes.link}>
          {`${LINES[lineKey].title ? LINES[lineKey].title: '-'} (${lineKey})`}
        </Link>
      </Typography>
    ));
  }

  return (
    <React.Fragment>
      <Grid item xs={12} className={classes.grid}>
        <Card className={classes.card}>
          <CardHeader
            className={classes.cardHeader}
            title={`My Recent Downloads`}
          />
          {loading && <LinearProgress />}
          <CardContent className={classes.cardContent}>
            <Paper className={classes.paper} elevation={0}>
              {!loading && manifests && manifests.length === 0 && (
                <Typography className={classes.text}>No favorites yet. Keep browsing</Typography>
              )}
              {!loading && manifests && manifests.length !== 0 && getList()}
            </Paper>
          </CardContent>
        </Card>
      </Grid>
    </React.Fragment>
  );
}
