// Packages
import React from "react";

// MUI Components
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";


// Styles
import { useStyles } from "./styles";

// Services

export default function PopUpWarning(props) {
  const classes = useStyles();
  const message = "One or more documents you requested have been blocked by your browser's pop-up blocker. Please enable pop-ups on this site to receive all materials.";

  return ( 
    <Dialog open={true} onClose={props.handleClose} maxWidth="xl" fullWidth>
      <FormControl className={classes.invalidContent} maxWidth="xl" fullWidth>
        <FormLabel id="files-label-helper" maxWidth="xl" fullWidth>{message}</FormLabel>
      </FormControl>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
