/**
 * @description This is the User Card component that is used
 * throught the application. It is used to quickly show a user
 * @author Findlay Clarke <findlayc@aaisonline.com>
 * @since 1.0.0
 * @module components/UserCard
 */

// Packages
import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";

// MUI Components
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import Fade from "@material-ui/core/Fade";

// Components
import UpdateUserDialog from "components/UpdateUserDialog";
import OverflowTooltip from "components/OverflowToolTip";

// Util
import * as userUtil from "util/userUtil";

// Services
import * as UserApi from "apis/UserApi";

// Context
import MeContext from "contexts/MeContext";
import OrgContext from "contexts/OrgContext";

// Styles
import { useStyles } from "./styles";

// Data
import ROLES from "enums/ROLES";

/**
 * @description The functional component for the User Card
 * @since 1.0.0
 * @public
 */
export default function UserCard(props) {
  const { userId, badgeContent } = props;
  const classes = useStyles();
  const [openUpdateUserDialog, setOpenUpdateUserDialog] = useState(false);
  const [user, setUser] = useState(UserApi.getFromCache(userId));
  const me = useContext(MeContext);
  const org = useContext(OrgContext);

  useEffect(() => {
    UserApi.get(userId).then((user) => {
      setUser(user);
    });
  }, [userId, badgeContent]);

  if (!user) return <React.Fragment />;

  return (
    <Grid item xs={12} sm={6} md={4} lg={3} xl={2} className={classes.grid}>
      <Fade in={true} timeout={1000}>
        <Card className={classes.card}>
          {badgeContent ? (
            <div className={classes.badgeContainer}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.badge}
              >
                {badgeContent}
              </Button>
            </div>
          ) : null}
          <CardContent data-test={"Content-" + userUtil.getDisplayName(user)}>
            <Grid container spacing={2} zeroMinWidth>
              <Grid item>
                <Avatar
                  className={classes.avatar}
                  alt={userUtil.getDisplayName(user)}
                  src={user.picture}
                  component={Link}
                  to={userUtil.getProfileLink(userId)}
                  data-test="Picture"
                >
                  {!user.picture ? userUtil.getInitials(user) : null}
                </Avatar>
              </Grid>
              <Grid item xs zeroMinWidth>
                <OverflowTooltip title={userUtil.getDisplayName(user)}>
                  <Typography
                    className={classes.title}
                    color="primary"
                    gutterBottom
                    data-test="userName"
                    component={Link}
                    to={userUtil.getProfileLink(userId)}
                  >
                  {userUtil.getDisplayName(user)}
                </Typography>
              </OverflowTooltip>
                <OverflowTooltip title={user.email}>
                  <Typography
                    component="span"
                    color="textPrimary"
                  >
                    {user.email}
                  </Typography>
                </OverflowTooltip>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions
            className={classes.cardActions}
            data-test={"Action-" + userUtil.getDisplayName(user)}
          >
            {userUtil.canDoOrg(me, ROLES.READ_WRITE, org) && (
              <React.Fragment>
                <Button
                  size="small"
                  color="secondary"
                  onClick={() => setOpenUpdateUserDialog(true)}
                >
                  Update
                </Button>
                {props.handleRemove && (
                  <Button
                    size="small"
                    color="secondary"
                    onClick={() => props.handleRemove(user)}
                  >
                    Remove
                  </Button>
                )}
              </React.Fragment>
            )}
          </CardActions>
        </Card>
      </Fade>
      {openUpdateUserDialog && (
        <UpdateUserDialog
          user={user}
          handleClose={() => setOpenUpdateUserDialog(false)}
        />
      )}
    </Grid>
  );
}
