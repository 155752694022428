// Packages
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  invalidContent:{
    marginTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
    height: "auto",
    width: "90%",
    fontWeight: "bold",
    textAlign: "left",
  },
}));
