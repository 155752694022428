// Packages
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  grid: {
    marginTop: 0,
    paddingLeft: 20,
    paddingRight: 20,
  },
  content: {
    paddingTop: 0,
    height: "100vh",
    width: "auto",
  },
  rightContent: {
    position: "absolute",
    paddingTop: 10,
    right: "25px",
    fontSize: "20px",
    cursor: "pointer",
  },
  contentText: {
    marginBottom: 0,
  },
  centerContent: {
    fontSize: "1rem",
    borderStyle: "inset",
    borderColor: "black",
    "&:hover": {
      textDecoration: "none",
      backgroundColor: "#D3D3D3"
    },
  },
  selectedCenterContent:{
    fontSize: "1rem",
    borderStyle: "inset",
    backgroundColor: "#C0C0C0",
    borderBottomColor: "#1f4287",
    borderColor: "black",
  },
  toolbar: {
    display: "none",
  },
  flexboxContainer: {
    display: "flex",
    flexDirection: "row",
  },
  tabsContent: {
    paddingLeft: "0px",
    paddingRight: "0px",
  },
  tabsContainer: {
    paddingLeft: 24,
    paddingBot: 0,
    paddingRight: 10,
    marginBottom: 0,
  }
}));
