// Packages
import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 240;

export const useStyles = makeStyles((theme) => ({
  badgeEmail: {
    "& span": {
      fontSize: 11,
    },
  },
  badge: {
    display: "flex",
    gap: 15,
  },
  header: { display: "flex", marginTop: 20 },
  listLink: {
    textDecoration: "none",
    color: "#fff",
    "&:hover": {
      textDecoration: "none",
    },
  },
  expandButton: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#fff",
    border: "3px solid #204286",
    marginTop: 57,
    marginLeft: -17,
    height: 20,
    width: 20,
    color: "#204286",
    "&:hover": {
      backgroundColor: "#ffffff",
    },
  },
  root: {
    display: "flex",
  },
  menuButton: {
    fill: "white",
    marginLeft: 15,
    marginRight: 36,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    backgroundColor: "#204286",
    color: "#fff",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    borderTopRightRadius: 40,
    borderBottomRightRadius: 40,
  },
  drawerClose: {
    backgroundColor: "#204286",
    color: "#fff",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
    borderTopRightRadius: 40,
    borderBottomRightRadius: 40,
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
  drawerIcon: { color: "white" },
  drawerHandlerIcon: {
    color: "white",
  },
  divider: { backgroundColor: "white" },
}));
