import React, { useState, useEffect } from "react";

// MUI Components
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { Container, Card, CardContent } from "@material-ui/core";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowUpIcon from '@material-ui/icons/ArrowDropUp';

// Styles
import { useStyles } from "./styles";

// Data
import PACKAGE_TYPES from "enums/PACKAGE_TYPES";

export default function CategoryTypesInfo(props) {
    const classes = useStyles();
    const [data, setData] = useState();

    function setUpCategory(value){
        //const fixedValue = value.replace(/\s/g, "_").toUpperCase();
        for (const type in PACKAGE_TYPES) {
            if (PACKAGE_TYPES[type].title === value) {//if (type === fixedValue) {//PACKAGE_TYPES[type].publicationType
                props.setPackageTypeCategory([PACKAGE_TYPES[type].publicationType]);
            }
        }
    }
    useEffect(() => {
        if(props.aggs){
            if('publicationTypeCategories' in props.aggs){
                const allDataCategories = props.aggs['publicationTypeCategories']['buckets'];

                allDataCategories.sort((a, b) => {
                    const nameA=a.key.toLowerCase(), nameB=b.key.toLowerCase();
                    if (nameA < nameB){
                        return -1;
                    }
                    if (nameA > nameB){
                        return 1;
                    }
                    return 0;
                  });
                const thisData = <React.Fragment>
                    <List className={classes.list}>
                    {allDataCategories.map( (item, i) => {
                            return <ListItem key={item.key} onClick={() => setUpCategory(item.key)} className={classes.listItemCategoryTypesInfo}><span><b>{item.key}: </b> &nbsp;{item.doc_count.toLocaleString()}</span></ListItem>;
                        })}
                    </List>
                </React.Fragment>;

                setData(thisData);

            }
            
        }
    }, [props.aggs]);  

    return (
        <React.Fragment>
            <Container disableGutters className={classes.container}>
                <Card elevation={5}>
                    <CardContent className={classes.downloadCard} onClick={() => props.setShowCategories(!props.showCategories)}>
                        <span className={classes.leftDownloadContent}>Material Types</span>
                        {!props.showDownloads &&
                            <ArrowDropDownIcon className={classes.menuItemA}></ArrowDropDownIcon>
                        }
                        {props.showDownloads &&
                            <ArrowUpIcon className={classes.menuItemB}></ArrowUpIcon>
                        }
                    </CardContent>
                    {props.showCategories && (
                        <div>{data}</div>
                    )}
                </Card>
            </Container>
        </React.Fragment>
    );
}