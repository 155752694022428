// Packages
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  grid: {
    flexGrow: 1,
  },
  card: {
    flexGrow: 1,
  },
  title: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: "bold",
    textDecoration: "none",
  },
}));
