/**
 * @description The enumeration of the contact types
 * @since 1.0.0
 * @enum {*}
 * @readonly
 * @public
 */
const CONTACT_TYPES = {
  /**
   * @constant {String}
   * @description The AAISdirect Company Admin contact
   * @since 1.0.0
   */
  AAIS_DIRECT_COMPANY_ADMIN: {
    key: "aaisDirectCompanyAdmin",
    title: "AAISdirect Administrator",
    description: "The AAISdirect Company Administrator",
  },
  /**
   * @constant {String}
   * @description Assessment Data Report contact.
   * @since 1.0.0
   */
  ASSESSMENT_DATA_REPORT: {
    key: "assessmentDataReport",
    title: "Assessment Data Reporting Contact",
    description: "The ADR contact",
  },
  /**
   * @constant {String}
   * @description The Meeting Notice and Proxy (Members Only) Contacts.
   * @since 1.0.0
   */
  MEETING_NOTICE_AND_PROXY: {
    key: "meetingNoticeAndProxy",
    title: "Meeting Notice and Proxy Contact",
    description: "The voting contact",
  },
  /**
   * @constant {String}
   * @description Statistical Reporting Contact
   * @since 1.0.0
   */
  STATISTICAL_REPORTING: {
    key: "statisticalReporting",
    title: "Statistical Reporting Contact",
    description: "The Stats contact",
  },

  /**
   * @constant {*}
   * @description The assessment invoice contact
   * @since 1.0.0
   */
  ASSESSMENT_INVOICE_CONTACT: {
    key: "assessmentInvoice",
    title: "Invoice Contact",
    description: "The assessment invoice contact",
  },
  /**
   * @constant {*}
   * @description The affiliation contact contact
   * @since 1.0.0
   */
  AFFILIATION_CONTACT: {
    key: "affilation",
    title: "Affilation Contact",
    description: "The affiliation contact contact",
  },
};

export default CONTACT_TYPES;
