// Data
import STATES from "enums/STATES";
import PACKAGE_TYPES from "enums/PACKAGE_TYPES";

export default class Hubspot {
  constructor() {
    this.portalId = process.env.REACT_APP_HUBSPOT_PORTALID;
    this.formId = process.env.REACT_APP_HUBSPOT_FORMID;
    this.legalConsentOptions = {
      // This option is not required, just add it if necessary
      consent: {
        consentToProcess: true,
        text: "I agree to allow AAIS to store and process my personal data.",
        communications: [
          {
            value: true,
            subscriptionTypeId: 999,
            text: "I agree to receive marketing communications from Example Company.",
          },
        ],
      },
    };
    this.data = {};
  }

  prepareData(submission) {
    const {
      //Excepting the email, firstName and phone, the fields below are related to the ticket that will be created.
      //All the user's fields are required, but some of the tickets fields aren't
      email,
      firstName,
      phone,
      subject, //Required, name for the ticket (it's not in the AAIS form, neither Hubspot has a default name for it, so it's given by code)
      content,
      effectiveDate,
      oldestDate,
      searchedTerms,
      excludeFileContent,
      products,
      productStates, //optional
      materialTypes,
      docTypes, //optional
      imgClass, //optional
      plans,//optional
      org,
    } = submission;
    const productStateString = productStates.length === 0
      ? "none"
      : productStates.join(";");
    const productString  = products.length === 0
    ? "none"
    : products.join(";");

    const fixedPackageTypes = docTypes.map((type) => type.title);
    const fixedPackageTypesCategories = materialTypes.map((key) => {
      for (const type in PACKAGE_TYPES) {
        if (PACKAGE_TYPES[type].publicationType === key) {
          return PACKAGE_TYPES[type].title;
        }
      }
    });


    const materialTypeString = fixedPackageTypesCategories.join(";");
    const doctypesString = fixedPackageTypes.join(";");
    const imgClassString = imgClass.join(";");
    const effectiveDateString = effectiveDate ? effectiveDate : "";
    const oldestDateString = oldestDate ? oldestDate : "";
    const plansString = plans.join(";");

    this.data = {
      fields: [
        {
          objectTypeId: "0-1",
          name: "email",
          value: email,
        },
        {
          objectTypeId: "0-1",
          name: "firstname",
          value: firstName,
        },
        {
          objectTypeId: "0-1",
          name: "phone",
          value: phone,
        },
        {
          objectTypeId: "0-1",
          name: "TICKET.product",
          value: productString,
        },
        {
          objectTypeId: "0-1",
          name: "TICKET.effective_date",
          value: effectiveDateString,
        },
        {
          objectTypeId: "0-1",
          name: "TICKET.oldest_date",
          value: oldestDateString,
        },
        {
          objectTypeId: "0-1",
          name: "TICKET.searched_terms",
          value: searchedTerms,
        },
        {
          objectTypeId: "0-1",
          name: "TICKET.exclude_file_content",
          value: excludeFileContent,
        },        
        {
          objectTypeId: "0-1",
          name: "TICKET.content",
          value: content,
        },
        {
          name: "TICKET.subject",
          value: subject,
        },
        {
          name: "TICKET.material_type",
          value: materialTypeString,
        },
        {
          name: "TICKET.product_state",
          value: productStateString,
        },
        {
          name: "TICKET.img_class",
          value: imgClassString,
        },
        {
          name: "TICKET.doc_type",
          value: doctypesString,
        },
        {
          name: "TICKET.plans",
          value: plansString,
        },
        {
          name: "TICKET.org",
          value: org,
        },
        
      ],
      context: {
        pageUri: process.env.REACT_APP_HUBSPOT_PAGEURI,
        pageName: process.env.REACT_APP_HUBSPOT_PAGENAME,
      },
    };
  }

  async sendProductHelpFormData() {
    const respAwait = await fetch(
      `https://api.hsforms.com/submissions/v3/integration/submit/${this.portalId}/${this.formId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(this.data),
      }
    );
    const response = await respAwait.json();
    return response;
  }
}
