// Packages
import React, { useState } from "react";

// MUI Components
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

// MUI Icons
import UpdatesIcon from "@material-ui/icons/Update";
import FileCopyIcon from "@material-ui/icons/FileCopy";

// Styles
import { useStyles } from "./styles";

export default function OrgTabsSection(props) {
  const { materialsTab, updatesTab } = props;
  const classes = useStyles();

  const [selectedTab, setTab] = useState(0);

  function handleChange(event, newSelectedTab) {
    setTab(newSelectedTab);
  }

  return (
    <React.Fragment>
      <Paper className={classes.root} elevation={0}>
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered={true}
        >
          <Tab label="Program Materials" icon={<FileCopyIcon />} />
        </Tabs>
      </Paper>
      {selectedTab === 0 && <React.Fragment>{materialsTab}</React.Fragment>}
      {selectedTab === 1 && <React.Fragment>{updatesTab}</React.Fragment>}
    </React.Fragment>
  );
}
