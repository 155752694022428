// Packages
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  grid: {
    flexGrow: 1,
  },
  card: {
    position: "relative",
  },
  cardActions: {
    display: "flex",
    justifyContent: "flex-end",
  },
  title: {
    fontSize: theme.typography.pxToRem(20),
    textDecoration: "none",
  },
  avatar: {
    width: 65,
    height: 65,
  },
  badgeContainer: {
    right: 2,
    top: -1,
    position: "absolute",
  },
  badge: {
    borderRadius: 15,
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 5,
    paddingLeft: 5,
    fontSize: 11,
    minWidth: 20,
  },
}));
