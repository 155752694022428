// Packages
import React, { useState } from "react";

// MUI Components
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import { useNavigate } from "react-router-dom";

// Services
import * as ProductApi from "apis/ProductApi";

export default function ProductDialog(props) {
  const [name, setName] = useState(props.name);
  const navigate = useNavigate();

  const handleClick = async() => {
    const newProduct = await ProductApi.create(name);

    const tempProducts = await ProductApi.getAll();

  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth={true}
      open={true}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Product Information</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Name"
          value={name}
          type="text"
          fullWidth
          onChange={(event) => setName(event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          Cancel
        </Button>
        <Button
          variant="contained"
          // component={RouterLink}
          // to='/products/productId'
          onClick={() => handleClick()}
          color="primary"
          disabled={
            !name ||
            name.length <= 4
          }
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}
