// Packages
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(25),
    fontWeight: theme.typography.fontWeightMedium,
  },
  expansionPanel: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "0px !important",
    color: "white",
  },
  expandMoreIcon: {
    backgroundColor: "white",
    borderRadius: 20,
  },
  expansionPanelDetails: {
    display: "flex",
    justifyContent: "flexStart",
    flexWrap: "wrap",
  },
  addressCard: {
    margin: 10,
    display: "flex",
  },
}));
