// Packages
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 30,
  },
  sectionTitle: {
    marginTop: 30,
    marginBottom: 5,
  },
}));
