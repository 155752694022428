import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    topMenuBar: {
        display: "flex",
        position: "fixed",
        alignItems: "center",
        backgroundColor: "#fafafa",
        color: "transparent",              // White text
        padding: "5px 20px",       // Padding for better spacing
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)", // Shadow for depth
        top: 0,                     // Align to the top of the viewport
        left: 0,                    // Align to the left of the viewport
        width: "100%",              // Full width of the viewport
        zIndex: 101
    },
    title: {
        margin: 0,                  // Remove margin for cleaner look
    },
    root: {
      display: "flex",
      height: "88.319px",
    },
    header: { 
        display: "flex",
        marginLeft: "5%",
        marginRight: "3%",
    },
    menuButton: {
      fill: "#204286",
      cursor: "pointer",
      "&:hover": {
      }
    },
    logo: {
        height: "68px",
        width: "161px"
    },
    menuItems: {
        display: "flex",
        listStyle: "none",
        color: "#383838",
        fontSize: "18px",
        fontWeight: "450",
    },
    item: {
        display: "flex",
        color: "#383838",
        transition: "color 0.2s, opacity 1s ease",
        cursor: "pointer",
        "&:hover": {
            color: "#0097AC",
        },
        "&:hover ul": {
            display: "block",
            opacity: 1,
            pointerEvents: "auto",
        }
    },
    badge: {
        display: "flex",
        marginRight: "2%",
        width: "100%",
        justifyContent: "flex-end"
    },
    badgeItem: {
        display: "flex",
        padding: "0px !important",
        transition: "color 0.2s, opacity 1s ease",
        cursor: "pointer",
        "&:hover": {
        },
        "&:hover ul": {
            display: "block",
            opacity: 1,
            minWidth: "180px",
            pointerEvents: "auto",
            transform: "translateX(-60%)"
        }
    },
    dropdown: {
        opacity: 0,
        pointerEvents: "none",
        display: "none", // Hide dropdown menu by default
        position: "absolute",
        top: "100%", // Position below the parent list item
        left: 0,
        backgroundColor: "#fafafa",
        padding: "5px",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)", // Shadow for depth
        minWidth: "250px", // Set a minimum width for dropdown menu
    },
    dropdownItem: {
        padding: "10px 20px",
        listStyle: "none",
        color: "#383838",
        cursor: "pointer",
        fontSize: "15px",
        transition: "color 0.2s",
        "&:hover": {
            color: "#0097AC"
        }
    },
    dropdownLink: {
        color: "#383838",
        cursor: "pointer",
        textDecoration: "none !important",
        fontSize: "15px",
        transition: "color 0.2s",
        "&:hover": {
            color: "#0097AC"
        }
    },
    active: {
        color: "#0097AC"
    },
    home: {
        fontSize: "18px",
        textDecoration: "none !important",
        color: "#383838",
        cursor: "pointer",
        transition: "color 0.2s",
        "&:hover": {
            color: "#0097AC"
        }
    }
}));