import { useEffect, useState } from "react";

export const constants = {
  deviceSize: {
    bigDesktop: 1260,
    desktop: 1024,
    tablet: 768,
    mobile: 320,
  },
  deviceType: {
    desktop: "desktop",
    mobile: "mobile",
  },
};

const useDevice = () => {
  const [device, setDevice] = useState("");

  const handlerRender = () => {
    window.innerWidth >= constants.deviceSize.tablet
      ? setDevice(constants.deviceType.desktop)
      : setDevice(constants.deviceType.mobile);
  };

  useEffect(() => {
    handlerRender();
    window.addEventListener("resize", handlerRender);
    return () => {
      window.removeEventListener("resize", handlerRender);
    };
  }, []);

  return device;
};

export default useDevice;
