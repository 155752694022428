// Packages
import React, { useContext, useEffect } from "react";
import { useLocation, Navigate } from "react-router-dom";

// MUI Components
import SummaryBar from "../../components/SummaryBar";
import Grid from "@material-ui/core/Grid";
import { Container } from "@material-ui/core";

// Components
import AdminLinksCard from "components/ProductAdminLinksCard";
import HubSpotCSVCard from "components/HubSpotCSVCard";

// Styles
import { useStyles } from "./styles";

// Context
import MeContext from "contexts/MeContext";

// Util
import * as userUtil from "util/userUtil";

export default function EmployeeDashboardScreen(props) {
  const classes = useStyles();
  const me = useContext(MeContext);
  const location = useLocation();

  useEffect(() => {
    window.gtag("event", "page_view", {
      page_title: "Employee Dashboard",
      page_path: `${window.location.pathname}${window.location.hash}`,
      path_location: window.location.href,
      send_to: `${process.env.REACT_APP_GA}`
    });
  }, [location]);

  function getTitle() {
    return `AAIS Employee Dashboard`;
  }

  if (me?.email && !userUtil.isEmployee(me)) {
    return <Navigate to={{ pathname: "/403" }} replace />;
  }

  return (
    <React.Fragment>
      <SummaryBar hideExpand={true} title={getTitle()}></SummaryBar>
      {me && userUtil.isEmployee(me) && (
        <Container>
          <Grid container className={classes.dashboard} spacing={2}>
            {me?.userId && (
              <React.Fragment>
                <Grid item xs={12} sm={4}>
                  <AdminLinksCard></AdminLinksCard>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <HubSpotCSVCard></HubSpotCSVCard>
                </Grid>
              </React.Fragment>
            )}
          </Grid>
        </Container>
      )}
    </React.Fragment>
  );
}
