// React
import React, { useEffect } from "react";

// MUI Components
import { Container } from "@material-ui/core";
import SummaryBar from "components/SummaryBar";
import Typography from "@material-ui/core/Typography";

// Styles
import { useStyles } from "./styles";

//Components
import FaqQuestion from "components/FaqQuestion";
import getFaqInfo from "./faqInfo";

const sections = getFaqInfo();

export default function FaqScreen() {
  const classes = useStyles();

  useEffect(() => {
    window.gtag("event", "page_view", {
      page_title: "FAQ Screen",
      page_path: `${window.location.pathname}${window.location.hash}`,
      path_location: window.location.href,
      send_to: `${process.env.REACT_APP_GA}`
    });
  }, []);

  return (
    <React.Fragment>
      <SummaryBar expanded={false} hideExpand={true} title="Frequently Asked Questions" />
      <Container maxWidth="md" className={classes.container}>
        {sections.map((section) => {
          return (
            <React.Fragment key={section.title}>
              <Typography
                variant="h6"
                className={classes.sectionTitle}
                key={section.title}
              >
                {section.title}
              </Typography>
              {section.questions.map((questionAnswer) => {
                return (
                  <FaqQuestion
                    questionAnswer={questionAnswer}
                    key={questionAnswer.question}
                  ></FaqQuestion>
                );
              })}
            </React.Fragment>
          );
        })}
      </Container>
    </React.Fragment>
  );
}
