// Packages
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  loadingRow: {
    padding: 0,
    height: 0,
  },
  loadingCell: {
    padding: 0,
  },
  link: {
    textDecoration: "none !important",
    cursor: "pointer",
    transition: "color 0.2s",
    "&:hover": {
        color: "#0097AC"
    }
  },
  removeUnderline: {
    textDecoration: "none !important",
  }
}));
