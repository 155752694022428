// Packages
import React, { useEffect, useContext } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Navigate } from "react-router-dom";

// Components
import SummaryBar from "components/SummaryBar";
import AllUsersTable from "components/AllUsersTable";

// Context
import MeContext from "contexts/MeContext";

// Util
import * as userUtil from "util/userUtil";

function UsersScreen(props) {
  const me = useContext(MeContext);

  useEffect(() => {
    window.gtag("event", "page_view", {
      page_title: "Users Screen",
      page_path: `${window.location.pathname}${window.location.hash}`,
      path_location: window.location.href,
      send_to: `${process.env.REACT_APP_GA}`
    });
  }, []);

  if (me?.email && !userUtil.isEmployee(me)) {
    return <Navigate to={{ pathname: "/403" }} replace />;
  }

  return (
    <React.Fragment>
      <SummaryBar hideExpand={true} expanded={false} title={"All Users"} />
      <AllUsersTable />
    </React.Fragment>
  );
}

const styles = (theme) => ({
  grid: {
    marginTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
  },
  left: {
    paddingRight: 10,
  },
  right: {
    paddingLeft: 10,
  },
});

export default withStyles(styles, { withTheme: true })(UsersScreen);
