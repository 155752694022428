import React from "react";

// MUI Components
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

// MUI Icons
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

// Styles
import { useStyles } from "./styles";

export default function FaqQuestion(props) {
  const classes = useStyles();
  return (
    <ExpansionPanel elevation={0} classes={{ expanded: classes.expanded }}>
      <ExpansionPanelSummary
        aria-controls="panel3d-content"
        id="panel3d-header"
        expandIcon={<KeyboardArrowDownIcon />}
      >
        <Typography>{props.questionAnswer.question}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.accordionDetails}>
        {props.questionAnswer.html ? (
          <Typography
            dangerouslySetInnerHTML={{ __html: props.questionAnswer.answer }}
          ></Typography>
        ) : (
          <Typography>{props.questionAnswer.answer}</Typography>
        )}

        {props.questionAnswer.img ? (
          <img
            className={classes.asnwerImg}
            src={`${process.env.PUBLIC_URL}/faq/${props.questionAnswer.img}`}
            alt={props.questionAnswer.img}
          />
        ) : (
          <></>
        )}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}
